import { makeStyles } from "@material-ui/styles";
export default makeStyles(theme => ({
  link: {
    //textDecoration: "none",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.sidebar.inactiveBgHover,

    },
    color: theme.palette.sidebar.inactiveColor,

  },
  externalLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none'
  },
  linkActive: {
    color: theme.palette.sidebar.activeColor,
    backgroundColor: theme.palette.sidebar.activeBg,
    '&.MuiSvgIcon-root': {
      color: theme.palette.sidebar.activeColor,
    },
    '& svg': {
      color: theme.palette.sidebar.activeColor,
    }
  },
  linkInactive: {
    color: theme.palette.sidebar.inactiveColor + '!important',
    '&.MuiSvgIcon-root': {
      color: theme.palette.sidebar.inactiveColor + '!important',
    },
    '& svg': {
      color: theme.palette.sidebar.inactiveColor + '!important',
    }
  },
  linkNested: {
    paddingLeft: 0,
    color: theme.palette.sidebar.inactiveColor,

    "&:hover, &:focus": {
      backgroundColor: "black",
    },
  },
  linkIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.sidebar.inactiveColor,

    transition: theme.transitions.create("color"),
    width: 24,
    display: "flex",
    justifyContent: "center",
  },
  linkIconActive: {
    color: theme.palette.sidebarActiveColor,
  },
  linkIconInactive: {
    color: theme.palette.sidebarInactiveColor
  },
  linkText: {
    padding: 0,
    //color: "#D6D6D6",
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 16,
  },
  /*  linkTextActive: {
    // color: "#D6D6D6",
   }, */
  linkTextHidden: {
    opacity: 0,
  },
  nestedList: {
    paddingLeft: theme.spacing(4),
  },
  sectionTitle: {
    marginLeft: theme.spacing(4.5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    height: 1,
    backgroundColor: "#D8D8D880",
  },
}));
