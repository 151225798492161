import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Select,
  MenuItem,
  Button,
  TextField,
  InputLabel,
  Typography,
  FormControlLabel,
  IconButton,
  CircularProgress, Checkbox, Tooltip, Paper
} from "@material-ui/core";
import InputAdornment from "@mui/material/InputAdornment";
import Autocomplete from '@mui/material/Autocomplete';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import ResponsiveDatePickers from "../../components/dateRange/datePicker";
import InsertChartIcon from '@mui/icons-material/InsertChart';

import moment from "moment";
import PercentIcon from '@mui/icons-material/Percent';

// styles
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import UserStateContext from "../../context/UserContext";
import UserService from "../../context/UserService";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import ContactoCarga from "../../components/ContactoCarga/ContactoCarga";
import { set } from "date-fns";
import MuiToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTheme } from "@material-ui/styles";
import { useMediaQuery, Container } from "@material-ui/core";
import { Alert, Collapse, AlertTitle } from "@mui/material";

import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Backdrop from '@material-ui/core/Backdrop';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from "@mui/icons-material/Close";
import TablaDescuento from "../../components/TablaDescuento/TablaDescuento";

const ToggleButton = styled(MuiToggleButton)(({ textColor, selectedColor, borderColor, disabledColor }) => ({
  color: 'inherit',
  borderColor: borderColor,
  borderRadius: '15px',
  textTransform: 'capitalize',
  maxHeight: '60px',
  '&.Mui-selected, &.Mui-selected:hover': {
    color: textColor,
    backgroundColor: selectedColor,
  },
  '&.Mui-disabled': {
    color: disabledColor,
    borderColor: borderColor,
  }
}));

const CustomPaper = (props) => {
  return <Paper elevation={8} {...props} />;
};

const EST_FR = 1001;

function EditCompra(props) {

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  var classes = useStyles();
  const { user } = useContext(UserStateContext);
  const rol = user.rol
  let canEdit = {
    faena: {
      precio: user.permissions.compras_can_edit_precio_faena === true,
      peso: user.permissions.compras_can_edit_peso_faena === true,
      comprador: user.permissions.compras_can_edit_comprador_faena === true,
      fechaCompra: user.permissions.compras_can_edit_fecha_compra_faena === true
    },
    invernada: {
      precio: user.permissions.compras_can_edit_precio_invernada === true,
      peso: user.permissions.compras_can_edit_peso_invernada === true,
      comprador: user.permissions.compras_can_edit_comprador_invernada === true,
      fechaCompra: user.permissions.compras_can_edit_fecha_compra_invernada === true
    }
  }

  //generals
  var [isLoading, setIsLoading] = useState(true);
  var [error, setError] = useState('');
  const [huboError, setHuboError] = useState(false);

  const [openFeedbackContacto, setOpenFeedbackContacto] = useState(false);
  const [responseErrorContacto, setResponseErrorContacto] = useState(false);
  const [establecimientos, setEstablecimientos] = useState([]);
  const [selectedEstablecimiento, setSelectedEstablecimiento] = useState(EST_FR);

  const handleCloseFeedbackContacto = () => {
    setOpenFeedbackContacto(false);
  }

  const handleChangeCampo = (event) => {
    setCampo(event.target.value);
  }

  const handleChangeFeedlot = (event) => {
    setFeedlot(event.target.value);
    let availableCampos = campos[event.target.value];
    if (availableCampos && availableCampos.length === 1) {
      setCampo(availableCampos[0].id);
    } else {
      setCampo(null);
    }
  }

  //accedo a la informacion de usuarios ya descargada.
  //const dataCompra = JSON.parse(localStorage.getItem('datatableCompras'));
  // saco indice para la informacion de usuarios. 
  //const params = window.location.href
  // numero de indice 
  //const idDataTable = params.split('/')[6]

  // // TODO Remover
  // useEffect(() => {
  //   if (dataCompra[idDataTable]['tipo_de_compra'] == 'Faena') {
  //     setHide(false)
  //     setFechaNegocio(null)
  //     setPlazo(dataCompra[idDataTable]['plazo'])
  //     setPlazoArray(Array(cuotaInvernada))
  //     setDisabledTipo(true)

  //   } else {
  //     setHide(true);
  //     setDisabledTipo(false)
  //     setPlazo('')
  //     setPlazoArray((dataCompra[idDataTable]['plazo']).split(','))

  //   }
  // }, []);

  const CargarValoresSegunTipoDeCompra = (tipo, data) => {

    // generales
    setId(data.id)
    settipoCompra(data.tipo_de_compra)
    setFechaCompra(data.fecha_de_compra)
    setComprador(data.comprador)
    setPlanta(data.planta)
    setStatus(data.status)
    setConsignatario(data.consignatario)
    setRemitente(data.remitente)
    setComisionista(data.comisionista)
    setCabezasComp(data.cabezas_compradas)
    //setPrecio(Math.round((data.precio * data.tc / (data.rinde / 100))))
    setPrecio(data.precio)
    setPeso(data.peso)
    setComision(data.comision)
    setObservaciones(data.observaciones)
    setIsPropia(data.is_propia)
    setIsNop(data.is_nop)
    setRemitente(data.remitente)
    setAligmentStatus(data.status)
    setCuotaInvernada(data.cuota_Inverada)
    setRinde(data.rinde)
    setGastosAdicionales(data.gastos_adicionales)
    setFeedlot(data.feedlot_id)
    setSelectedEstablecimiento(data.establecimiento_id);

    if (tipo == 'Faena') {
      setHide(false)
      setFechaNegocio(null)
      setPlazo(data.plazo)
      setPlazoArray(Array(data.cuota_Inverada))
      setDisabledTipo(true)
      setFechaFaena(data.fecha_de_faena)
      setDestinoComercial(data.destino_Comercial)
      setCuota(data.cuota)
      setRaza(data.raza)
      setEspecie(data.especie)
      setPrograma(data.programa_real)
      setUE(data.cuota != '')
      setHQF(data.hqf)
      setConRinde(data.rinde != 1)
      setRinde(data.rinde)
      setAligmentCuota(data.cuota)
      setAligmentDestino(data.destino_Comercial)
      setAligmentRaza(data.raza)
      setAligmentEspecie(data.especie)
      setAligmentPrograma(data.programa_real)
      setVerHqf(() => {
        if (data.cuota == 'Hilton' || data.destino_Comercial != 'Exportación UE' || data.programa != 'GRAIN FED') {
          return false
        } else {
          return true
        }
      })
      setTrackOrigen({
        fechaFaena: data.fecha_de_faena,
        fechaCarga: data.fecha_de_carga,
        cabezasComp: data.cabezas_compradas,
        precio: Math.round((data.precio * data.tc / (data.rinde / 100))),
        peso: data.peso
      });
      setTrackCambios({
        fechaFaena: data.fecha_de_faena,
        fechaCarga: data.fecha_de_carga,
        cabezasComp: data.cabezas_compradas,
        precio: Math.round((data.precio * data.tc / (data.rinde / 100))),
        peso: data.peso
      });
    } else {
      setHide(true);
      setDisabledTipo(false)
      setPlazo('')
      setPlazoArray((data.plazo).split(','))
      setFechaCarga(data.fecha_de_carga)
      setFechaNegocio(data.fecha_de_negocio)
      setLugarDeCarga(data.lugar_de_carga)
      setFlete(data.flete)
      setContactoDeCarga(data.contacto_de_carga_id)
      setDesbaste(data.desbaste)
      setKm(data.kilómetros_aproximados)
      setJaulaS(data.jaula_simple)
      setJaulaD(data.jaula_doble)
      setCantidad(data.cantidad)
      setProcedencia(data.procedencia)
      setPrecioFlete(data.precio_flete)
      setCondicionPago(data.condicion_pago_id)
      setCampo(data.campo_id)
      setIsRecria(data.is_recria)
      setIsFeedlot(data.is_feedlot)
      setTablaId(data.tabla_descuento_id)
      if (data.tabla_descuento_id) {
        setShowTablaDescuento(true);
      }
      setDescuentoComercial(data.descuento_comercial * 100)
    }
  }

  const handleChangeDesbaste = (event) => {
    if (event.target.value !== '') {
      setDesbaste(event.target.value);
    } else {
      setDesbaste(null);
    }
  }

  const handleChangeTypeFeedlot = (event) => {
    setIsFeedlot(event.target.checked);
    if (event.target.checked === true) {
      setIsRecria(false);
    }
  }
  const handleChangeTypeRecria = (event) => {
    setIsRecria(event.target.checked);
    if (event.target.checked === true) {
      setIsFeedlot(false);
    }
  }

  // Obtenemos los datos de la compra y seteamos los valores
  useEffect(() => {
    const fetchData = async () => {
      const params = window.location.href
      const id_compra = params.split('/')[6]
      setIsLoading(true);
      try {
        const response = await UserService.getCompra(id_compra);

        if (response.status == 200) {
          CargarValoresSegunTipoDeCompra(response.data.data.tipo_de_compra, response.data.data)
        } else {
          setError("Ocurrio un error al cargar la compra")
          setHuboError(true)
        }
      } catch (error) {
        setError("Ocurrio un error al cargar la compra");
        setHuboError(true)
        console.log(error.message + ' : ' + id_compra);
      }
      setIsLoading(false);

    };
    fetchData();
  }, []);

  // local

  var [id, setId] = useState(null)
  const [idCompra, setIdCompra] = useState(null)
  var [tipoCompra, settipoCompra] = useState(null);

  var [disabledTipo, setDisabledTipo] = useState(false)
  const [rindeError, setRindeError] = useState(false);

  var [fechaCompra, setFechaCompra] = useState(null);
  var [fechaCarga, setFechaCarga] = useState(null);
  var [fechaNegocio, setFechaNegocio] = useState(null)
  var [fechaFaena, setFechaFaena] = useState(null)
  var [comprador, setComprador] = useState(null)
  var [planta, setPlanta] = useState(null)
  var [status, setStatus] = useState(null)
  var [consignatario, setConsignatario] = useState(null)
  var [remitente, setRemitente] = useState(null)
  var [destinoComercial, setDestinoComercial] = useState(null)
  var [cuota, setCuota] = useState(null)
  var [raza, setRaza] = useState(null)
  var [especie, setEspecie] = useState(null)
  var [programa, setPrograma] = useState(null);
  var [cuotaInvernada, setCuotaInvernada] = useState("");
  var [comisionista, setComisionista] = useState(null)
  var [procedencia, setProcedencia] = useState('');
  var [observaciones, setObservaciones] = useState(null)
  const [hqf, setHQF] = useState(null)
  const [verHQF, setVerHqf] = useState(false)

  // invernada
  const [tablaId, setTablaId] = useState(null);
  const [showTablaDescuento, setShowTablaDescuento] = useState(false);
  const [descuentoComercial, setDescuentoComercial] = useState(null);
  var [contactoDeCarga, setContactoDeCarga] = useState(null);
  var [lugarDeCarga, setLugarDeCarga] = useState(null);
  var [feedlot, setFeedlot] = useState(null);
  var [flete, setFlete] = useState(null);
  var [nuevoContacto, setNuevoContacto] = useState("");
  const [condicionPago, setCondicionPago] = useState("");

  //campos con numero 
  var [cabezasComp, setCabezasComp] = useState('');
  var [precio, setPrecio] = useState('');
  var [peso, setPeso] = useState('');
  var [comision, setComision] = useState(null);
  var [plazo, setPlazo] = useState('');
  var [plazoArray, setPlazoArray] = useState('');
  var [Desbaste, setDesbaste] = useState(null);
  var [km, setKm] = useState(0);
  var [JaulaS, setJaulaS] = useState('');
  var [JaulaD, setJaulaD] = useState('');
  var [cantidad, setCantidad] = useState(0);
  const [precioFlete, setPrecioFlete] = useState("");
  const [gastosAdicionales, setGastosAdicionales] = useState(0);

  // Rindes
  var [conRinde, setConRinde] = useState(null);
  var [rinde, setRinde] = useState(null);

  // es propia
  const [isPropia, setIsPropia] = useState(false);

  const [isRecria, setIsRecria] = useState(false);
  const [isFeedlot, setIsFeedlot] = useState(false);

  // es nop
  const [isNop, setIsNop] = useState(false);
  const [showNop, setShowNop] = useState(false);

  // cambio de formulario
  const [hide, setHide] = useState(false);

  // esconder cuota segun tipo 
  var [UE, setUE] = useState(false)

  //valoresCheck
  const [checkDestino, setCheckDestino] = useState([]);
  const [checkCuota, setCheckCuota] = useState([]);
  const [checkRaza, setCheckRaza] = useState([]);
  const [checkEspecie, setCheckEspecie] = useState([]);
  const [checkPrograma, setCheckPrograma] = useState([]);

  // valores desplegables
  const [desplegables, setDespletables] = useState([])
  const [contactoDC, setContactoDC] = useState([])
  const [feedlotDesp, setFeedlotDesp] = useState([])
  const [CompradoresDeslp, setCompradoresDeslp] = useState([])
  const [condicionesPago, setCondicionesPago] = useState([])
  const [campos, setCampos] = useState([]);
  const [campo, setCampo] = useState(null);
  //state desplegables
  const [mostrarConsig, setMostrarConsig] = useState(true)
  const [mostrarRemitente, setMostrarRemitente] = useState(true)
  const [mostrarComisionista, setMostrarComisionista] = useState(true)
  const [mostrarFlete, setMostrarFlete] = useState(true)
  //alineacion tooglebuttongroup
  const [alignment, setAlignment] = React.useState('Faena');
  const [alignmentStatus, setAligmentStatus] = React.useState(null);
  const [alignmentDestino, setAligmentDestino] = React.useState(null);
  const [alignmentCuota, setAligmentCuota] = React.useState(null);
  const [alignmentRaza, setAligmentRaza] = React.useState(null);
  const [alignmentEspecie, setAligmentEspecie] = React.useState(null);
  const [alignmentPrograma, setAligmentPrograma] = React.useState(null);

  // contacto de carga
  const [mostrarContacto, setMostrarContacto] = useState(false);
  const [nombre, setNombre] = useState()
  const [apellido, setApellido] = useState()
  const [telefono, setTelefono] = useState()

  // Mail de actualizacion y track de cambios
  const [enviarMailActualizacion, setEnviarMailActualizacion] = useState(false);
  const [arrayCambios, setArrayCambios] = useState({});
  const [habilitarSwitch, setHabilitarSwitch] = useState(false);
  const [trackOrigen, setTrackOrigen] = useState({
    fechaFaena: null,
    fechaCarga: null,
    cabezasComp: null,
    precio: null,
    peso: null
  });
  const [trackCambios, setTrackCambios] = useState({
    fechaFaena: null,
    fechaCarga: null,
    cabezasComp: null,
    precio: null,
    peso: null
  });

  // Error / Success update request
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [responseLoading, setResponseLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    if (openError === false) {
      // push to previous page
      props.history.goBack();
    }
  }

  const handleCancelar = () => {
    props.history.goBack();
  }

  const handleUpdate = async () => {
    let response;
    setOpenError(false);
    setResponseLoading(true);
    try {
      if (tipoCompra == "Faena") {
        response = await UserService.putCompra(id, tipoCompra, fechaCompra, fechaNegocio, fechaCarga, fechaFaena, comprador, planta, status, consignatario, remitente, destinoComercial, cuota,
          cuotaInvernada, raza, especie, programa, programa, contactoDeCarga, lugarDeCarga, feedlot, cantidad, Desbaste,
          km, flete, JaulaS, JaulaD, cabezasComp, precio, peso, plazo, comisionista, comision, procedencia, observaciones, hqf, user.id, rinde, enviarMailActualizacion, arrayCambios, isPropia, isNop,
          precioFlete !== "" ? precioFlete : 0,
          gastosAdicionales,
          null,
          null,
          false,
          false,
          selectedEstablecimiento,
          null, // descuento comercial
          null // tabla de descuento id
          // props.history,
          // setIsLoading,
          // setError,
        )
      } else {
        response = await UserService.putCompra(id, tipoCompra, fechaCompra, fechaNegocio, fechaCarga, fechaFaena, comprador, planta, status, consignatario, remitente, destinoComercial, cuota,
          cuotaInvernada, raza, especie, programa, programa, contactoDeCarga, lugarDeCarga, feedlot, cantidad, Desbaste,
          km, flete, JaulaS, JaulaD, cabezasComp, precio, peso, plazoArray.toString(), comisionista, comision, procedencia, observaciones, hqf, user.id, rinde, enviarMailActualizacion, arrayCambios, isPropia, isNop,
          precioFlete !== "" ? precioFlete : 0,
          gastosAdicionales,
          condicionPago,
          campo,
          isRecria,
          isFeedlot,
          selectedEstablecimiento,
          descuentoComercial ? descuentoComercial/100 : 0.0,
          tablaId
          // props.history,
          // setIsLoading,
          // setError,
        )
      }
      if (response.status !== 200) {
        setOpenError(true);
      }
      setResponseLoading(false);
    } catch (error) {
      console.log(error);
      setResponseLoading(false);
      setOpenError(true);
    }

    setOpen(true)
  }

  // Verificar si hubo cambios
  const hasChanges = () => {
    const huboCambios =
      trackCambios.fechaFaena != trackOrigen.fechaFaena ||
      trackCambios.fechaCarga != trackOrigen.fechaCarga ||
      trackCambios.cabezasComp != trackOrigen.cabezasComp ||
      trackCambios.precio != trackOrigen.precio ||
      trackCambios.peso != trackOrigen.peso;

    const cambios = {};
    if (trackCambios.fechaFaena != trackOrigen.fechaFaena) {
      cambios['fechaFaena'] = {
        new: trackCambios.fechaFaena,
        old: trackOrigen.fechaFaena
      }
    }
    if (trackCambios.fechaCarga != trackOrigen.fechaCarga) {
      cambios['fechaCarga'] = {
        new: trackCambios.fechaCarga,
        old: trackOrigen.fechaCarga
      }
    }
    if (trackCambios.cabezasComp != trackOrigen.cabezasComp) {
      cambios['cabezasComp'] = {
        new: parseInt(trackCambios.cabezasComp),
        old: trackOrigen.cabezasComp
      }
    }
    if (trackCambios.precio != trackOrigen.precio) {
      cambios['precio'] = {
        new: parseInt(trackCambios.precio),
        old: trackOrigen.precio
      }
    }
    if (trackCambios.peso != trackOrigen.peso) {
      cambios['peso'] = {
        new: parseInt(trackCambios.peso),
        old: trackOrigen.peso
      }
    }
    setArrayCambios(cambios);
    //console.log(cambios)
    return huboCambios

  };

  // Effect para habilitar el switch ----------------------------------
  // ! Por ahora pidieron que no se envie el mail de actualizacion...
  /* 
  useEffect(() => {
    const huboCambios = hasChanges();
    if (huboCambios === true && habilitarSwitch === true) {
      setEnviarMailActualizacion(true);
    }
    if (!huboCambios === true) {
      setEnviarMailActualizacion(false);
    }
    setHabilitarSwitch(!huboCambios);


  }, [trackCambios]);
  */

  const getCondicionesDePago = () => {
    UserService.getCondicionesPago()
      .then((response) => {
        if (response.status === 200) {
          setCondicionesPago(response.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // useEffect para mostrar u ocultar Nop
  useEffect(() => {
    if (programa === "ORGANIC") {
      setShowNop(true);
    } else {
      setShowNop(false);
      setIsNop(false);
    }
  }, [programa]);


  // Actualizar el estado de los cambios
  const handleChangeOrigin = (event) => {
    const { name, value } = event.target;
    setTrackCambios((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const clearState = () => {

    setFechaCompra(moment().format("YYYY-MM-DD"));
    setFechaFaena(moment().format("YYYY-MM-DD"));
    setFechaNegocio(null)
    setComprador("");
    setPlanta("1");
    setStatus("");
    setConsignatario("");
    setRemitente("");
    setDestinoComercial("");
    setCuota("");
    setRaza("");
    setEspecie("");
    setPrograma('');
    setCuotaInvernada(0)
    setComisionista('');
    setProcedencia('');
    setObservaciones('');
    setContactoDeCarga("");
    setLugarDeCarga("");
    setFeedlot("");
    setFlete("");
    setNuevoContacto("");
    //campos con numero 
    setCabezasComp(0);
    setPrecio(0);
    setPeso(0);
    setComision(null);
    setPlazo(0);
    setDesbaste(null);
    setKm(0);
    setJaulaS(0);
    setJaulaD(0);
    setCantidad(0);
    setPrecioFlete(0);

    setEnviarMailActualizacion(false);
    setTrackOrigen({});
    setTrackCambios({});
    setArrayCambios({});


  }
  //funcion para desabilitar el hqf
  const cuotaResult = () => {
    if (cuota == 'Hilton' || destinoComercial != 'Exportación UE') { return true }
    else {
      return false
    }
  }

  // cambio array de plazo 
  const onchangeInput = (val, index) => {

    let temp = plazoArray.map(i => i);
    temp[index] = val.target.value;
    setPlazoArray(temp);
  };
  // funcion para generar los select de proveedores ( Despues se va a cambiar)  
  const generarSelect = (data) => {
    return data.map(link => <MenuItem value={link.cuit_proveedor + '-' + link.razon_social}>{link.razon_social}</MenuItem>)
  }
  // funcion para desabilitar campos en caso de comprador Estandard
  const disabilitado = (subkey) => {
    let key = tipoCompra === 'Faena' ? 'faena' : 'invernada';
    return !canEdit[key][[subkey]];
  }


  function handleClickStatus(event) {
    if (event.target.value === status) {
      setStatus("");
    } else {
      setStatus(event.target.value);
    }
  }
  function handleClickDestino(event) {
    setPrograma('')
    setAligmentPrograma('')
    setVerHqf(false)
    setHQF(false)

    if (event.target.value == 'Exportación UE') {
      setUE(true)
      //if(cuota!='Hilton' && programa=='GRAIN FEED'){setVerHqf(true)}

    } else {
      setAligmentDestino('')
      setAligmentCuota()
      //setVerHqf(true)


      setUE(false)
      setCuota("");


    }
    if (event.target.value === destinoComercial && event.target.value == 'Exportación UE') {
      setDestinoComercial("");
      setUE(false)
      setCuota("");
      setAligmentDestino('')
      //setVerHqf(true)




    } else {
      setDestinoComercial(event.target.value);
      setAligmentDestino(event.target.value)
      setCuota("");
      //setVerHqf(true)

    }
    // cambio estado de hqf
    if (event.target.value != 'Exportación UE') {
      setHQF(false)
      setVerHqf(false)


    }

  }


  function handleClickCuota(event) {
    setPrograma('')
    setAligmentPrograma('')
    setVerHqf(false)
    setHQF(false)
    if (event.target.value === cuota) {
      setCuota("");
      setAligmentCuota('')
      setVerHqf(true)


    } else {
      setCuota(event.target.value);
      setAligmentCuota(event.target.value)
      //setVerHqf(true)
    }

    if (event.target.value == 'Hilton') {
      setHQF(false)
      setVerHqf(false)

    }

  }

  function handleClickRaza(event) {
    setAligmentRaza(event.target.value)
    if (event.target.value === raza) {
      setRaza("");
    } else {
      setRaza(event.target.value);
    }
  }
  function handleClickEspecie(event) {
    if (event.target.value === especie) {
      setEspecie("");
      setAligmentEspecie("")

    } else {
      setEspecie(event.target.value);
      setAligmentEspecie(event.target.value)

    }
  }

  function handleClickPrograma(event) {
    if (event.target.value === programa) {
      setPrograma("");
      setAligmentPrograma('')

    } else {
      setAligmentPrograma(event.target.value)
      setPrograma(event.target.value);
    }

    if (event.target.value == 'GRAIN FED' && cuota != 'Hilton' && destinoComercial == 'Exportación UE') {
      setVerHqf(true)
    } else {
      setVerHqf(false)
      setHQF(false)


    }


  }

  const fetchDataCheck = async () => {
    const dataCheck = await UserService.getDestinoComercial()
    setCheckDestino(dataCheck[0])
    setCheckRaza(dataCheck[1])
    setCheckCuota(dataCheck[2])
    setCheckEspecie(dataCheck[3])
    setCheckPrograma(dataCheck[4])
  }

  const handleTipoCompra = (e) => {
    settipoCompra(e.target.value)
    if (tipoCompra == 'Faena') {
      setHide(true)
      clearState()
    } else {
      setHide(false)
      clearState()
      setFechaNegocio(null)
    }

  }
  useEffect(() => {

    // busco data de checkbox
    fetchDataCheck()
    // busco listado de proveedores
    UserService.getListProveedor().then(
      response => {
        if (response.status == 200) {
          const dataTropaConsig = response.data.users.map((a, index) => ({
            cuit: a.cuit,
            label: a.razonS + ' - ' + a.cuit,
            razon_social: a.razonS,
            id: index
          }))

          setDespletables(dataTropaConsig)
        }
      },
      error => {
        if (error.response.status == 401) { }
      }
    );
    // busco listado de contactos de carga
    UserService.getListContactoDeCarga().then(
      response => {
        if (response.status == 200) {
          setContactoDC(response.data.data)
        }
      },
      error => {
        if (error.response.status == 401) { }
      }
    );
    // busco listado de contactos de carga
    UserService.getListFeedlot().then(
      response => {
        if (response.status == 200) {
          let camposPerFeedlot = {};
          const dataTropaConsig = response.data.users.map((a) => ({
            id: a.id,
            nombre: a.nombre
          }));
          const camp = response.data.users.map((a) => {
            if (a.campos && a.campos.length > 0) {
              camposPerFeedlot[a.id] = a.campos.map((b) => (b))
            }
          })
          setFeedlotDesp(dataTropaConsig);
          setCampos(camposPerFeedlot);
        }
      },
      error => {
        if (error.response.status == 401) { }
      }
    );
    UserService.getListcompradores().then(
      response => {
        if (response.status == 200) {
          setCompradoresDeslp(response.data.data)
        }
      },
      error => { if (error.response.status == 401) { } }
    );
    UserService.getEstablecimientos().then(
      response => {
        if (response.status == 200) {
          setEstablecimientos(response.data)
        }
      }
    )
  }, []);

  useEffect(() => {
    if (tipoCompra === "Invernada" && condicionesPago.length === 0) {
      getCondicionesDePago();
    }
  }, [tipoCompra]);

  const handleDescuentoComercialChange = (e) => {
    setDescuentoComercial(parseFloat(e.target.value));
  }

  const handleShowTablaDescuentoChange = () => {
    setShowTablaDescuento(!showTablaDescuento);
  };

  return (
    <>
      {/* <PageTitle title="Editar Compra" /> */}
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {isLoading ? (
            <Backdrop className={classes.backdrop} open={isLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <>
              {(huboError) ? (
                <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                  <Typography color="secondary" className={classes.errorMessage} variant="h3">
                    {error}
                  </Typography>
                </div>
              ) : (
                <Widget disableWidgetMenu
                  upperTitle
                  bodyClass={classes.fullHeightBody}
                  className={classes.card}
                >
                  <div className={classes.form}>
                    <Grid container item lg={8} spacing={2}>
                      <Grid contaimer item lg={12} md={12} xs={12} sm={12} style={{ display: 'flex' }} spacing={2}>
                        <Grid item lg={3} md={3} xs={6} sm={6}>
                          <InputLabel id="type">Tipo de Compra</InputLabel>
                          <ToggleButtonGroup
                            value={tipoCompra}
                            size={isSmallScreen ? "small" : "medium"}
                            exclusive
                          //onChange={handleTipoCompra}
                          >
                            <ToggleButton value="Faena" aria-label="left aligned" disabled={!disabledTipo} disabledColor={theme.palette.action.disabled} borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }} >Faena</ToggleButton>
                            <ToggleButton value="Invernada" aria-label="left aligned" disabled={disabledTipo} disabledColor={theme.palette.action.disabled} borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }}>{isSmallScreen ? 'Inv.' : 'Invernada'}</ToggleButton>
                          </ToggleButtonGroup>
                        </Grid>
                        <Grid item lg={3} md={3} xs={12} sm={12} justifyContent='center' style={{ marginLeft: isSmallScreen ? '20px' : '0px' }}>
                          <InputLabel id="type">Comprador*</InputLabel>
                          <Select
                            labelId="type"
                            variant="outlined"
                            id="sector"
                            size={isSmallScreen ? "small" : "medium"}
                            value={comprador}
                            label="Comprador"
                            disabled={disabilitado('comprador')}
                            onChange={(e) => { setComprador(e.target.value) }}
                            fullWidth
                          >
                            {CompradoresDeslp.map(link => {
                              return <MenuItem value={link.email}>{link.username} {link.last_name}</MenuItem>
                            })}

                          </Select>
                        </Grid>
                      </Grid>
                      <Grid item alignItems="center" lg={3} md={6} xs={12} sm={12}>
                        <InputLabel id="type">Fecha de Compra</InputLabel>
                        <ResponsiveDatePickers
                          disabled={disabilitado('fechaCompra')}
                          value={fechaCompra}
                          tipo={'Compra'}
                          setValorFecha={(e) => { setFechaCompra(moment(e).format("YYYY-MM-DD")) }}
                        ></ResponsiveDatePickers>
                      </Grid>
                      {hide && (

                        <Grid item alignItems="center" lg={3} md={3} xs={12} sm={12}>
                          <InputLabel id="type">Fecha de Carga</InputLabel>
                          <ResponsiveDatePickers
                            value={fechaCarga}
                            tipo={'Carga'}
                            setValorFecha={(e) => {
                              setFechaCarga(moment(e).format("YYYY-MM-DD"));
                              setTrackCambios((prevState) => ({ ...prevState, fechaCarga: moment(e).format("YYYY-MM-DD") }));
                            }}
                            dontDisabledWeekend={true}
                          ></ResponsiveDatePickers>
                        </Grid>)}
                      {!hide && (
                        <Grid item alignItems="center" lg={3} md={6} xs={6} sm={6}>
                          <InputLabel id="fechaCompra">Fecha de Faena*</InputLabel>
                          <ResponsiveDatePickers
                            value={fechaFaena}
                            tipo={'Faena'}
                            setValorFecha={(e) => {
                              setFechaFaena(moment(e).format("YYYY-MM-DD"));
                              setTrackCambios((prevState) => ({ ...prevState, fechaFaena: moment(e).format("YYYY-MM-DD") }));
                              //if (moment(e).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")) {
                              //}
                            }}
                          ></ResponsiveDatePickers>


                        </Grid>
                      )}
                      {hide && (
                        <Grid item alignItems="center" lg={3} md={6} xs={12} sm={12}>

                          <InputLabel id="type">Fecha de Negocio</InputLabel>
                          <ResponsiveDatePickers
                            tipo={'Negocio'}

                            value={fechaNegocio}
                            setValorFecha={(e) => { setFechaNegocio(moment(e).format("YYYY-MM-DD")) }}
                            dontDisabledWeekend={true}
                          ></ResponsiveDatePickers>

                        </Grid>)}

                      {false && (
                        <Grid item lg={12} xs={12} sm={6}>
                          <TextField
                            id="planta"
                            InputProps={{
                              classes: {
                                underline: classes.textFieldUnderline,
                                input: classes.textField,
                              },
                            }}
                            value={planta}
                            onChange={e => setPlanta(e.target.value)}
                            margin="normal"
                            variant="outlined"
                            label="planta"
                            disabled

                            placeholder="Razón Social"
                            type="text"
                            fullWidth />
                        </Grid>)}
                      {hide && (
                        <Grid item lg={6} md={6} xs={12} sm={12}>
                          <TextField
                            id="lugarDeCarga"
                            InputProps={{
                              classes: {
                                underline: classes.textFieldUnderline,
                                input: classes.textField,
                              },
                            }}
                            value={lugarDeCarga}
                            onChange={e => setLugarDeCarga(e.target.value)}
                            margin="normal"
                            variant="outlined"
                            label="Lugar de Carga"
                            placeholder="Lugar de Carga"
                            type="text"
                            fullWidth />
                        </Grid>)}
                      <Grid container item lg={12} md={12} xs={12} sm={12}>

                        <Grid item lg={6} md={6} xs={12} sm={12}>
                          <InputLabel id="type">Establecimiento</InputLabel>
                          <Select
                            labelId="type"
                            variant="outlined"
                            id="establecimiento"
                            value={selectedEstablecimiento}
                            label="Establecimiento"
                            onChange={(e) => { setSelectedEstablecimiento(e.target.value) }}
                            fullWidth
                          >
                            {establecimientos.map(link => {
                              return (
                                <MenuItem value={link.id} key={link.id}>
                                  {link.nombre}
                                </MenuItem>
                              )
                            }

                            )}
                          </Select>
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                          {(feedlot !== "" && feedlot !== null) && (
                            <Tooltip title={'Limpiar seleccion'}>
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  setSelectedEstablecimiento(null);
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Grid>
                      </Grid>

                      <Grid container item lg={12} md={12} xs={12} sm={12}>

                        <Grid item lg={6} md={6} xs={12} sm={12}>
                          <InputLabel id="type">Feedlot</InputLabel>
                          <Select
                            labelId="type"
                            variant="outlined"
                            id="Feedlot"
                            value={feedlot}
                            label="Feedlot"
                            onChange={(e) => { setFeedlot(e.target.value) }}
                            fullWidth
                          >
                            {feedlotDesp.map(link => {
                              return (
                                <MenuItem value={link.id} key={link.id}>
                                  {link.nombre}
                                </MenuItem>
                              )
                            }

                            )}
                          </Select>
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                          {(feedlot !== "" && feedlot !== null) && (
                            <Tooltip title={'Limpiar seleccion'}>
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  setFeedlot(null);
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Grid>
                      </Grid>
                      {(hide && feedlot) && (
                        <Grid container item lg={12} md={12} xs={12} sm={12}>
                          <Grid item lg={6} md={6} xs={10} sm={10}>
                            <InputLabel id="campo-selector">Campo</InputLabel>
                            <Select
                              labelId="campo-selector"
                              id="campo-selector"
                              value={campo}
                              label="Campo"
                              onChange={handleChangeCampo}
                              variant="outlined"
                              fullWidth
                            >
                              {campos[feedlot]?.map((campo) => (
                                <MenuItem key={campo.id} value={campo.id}>
                                  {campo.nombre}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          {(campo !== "" && campo !== null) && (
                            <>
                              <Grid item lg={1} md={1} sm={1} xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                                <Tooltip title={'Limpiar seleccion'}>
                                  <IconButton
                                    aria-label="delete"
                                    onClick={() => {
                                      setCampo(null);
                                    }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                              <Grid item lg={2} md={2} sm={2} xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox checked={isFeedlot} onChange={handleChangeTypeFeedlot} name="is_feedlot" />}
                                    label="Feedlot"
                                    style={!isSmallScreen ? ({ marginLeft: '10px' }) : ({ marginTop: '10px' })}
                                  />
                                  <FormControlLabel
                                    control={<Checkbox checked={isRecria} onChange={handleChangeTypeRecria} name="is_recria" />}
                                    label="Recria"
                                    style={!isSmallScreen ? ({ marginLeft: '10px' }) : ({ marginTop: '10px' })}
                                  />
                                </FormGroup>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      )}
                      <Grid item lg={12} md={12} xs={12} sm={12}>
                        <InputLabel id="type">Status</InputLabel>
                        <ToggleButtonGroup
                          value={alignmentStatus}
                          exclusive
                          size={isSmallScreen ? "small" : "medium"}
                          onChange={((e) => { setStatus(e.target.value); setAligmentStatus(e.target.value) })}
                        >
                          <ToggleButton value="SinConfirmar" aria-label="left aligned" borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }} >SC</ToggleButton>
                          <ToggleButton value="tramite" aria-label="left aligned" borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }} >ET</ToggleButton>
                          <ToggleButton value="confirmado" aria-label="left aligned" borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }}>CO</ToggleButton>
                          <ToggleButton value="arribado" aria-label="left aligned" borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }}>ADO</ToggleButton>
                          <ToggleButton value="postergaCarga" aria-label="left aligned" borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }}>PC</ToggleButton>


                        </ToggleButtonGroup>
                        <FormControlLabel
                          control={<Checkbox checked={isPropia} onChange={(event) => { setIsPropia(event.target.checked) }} name="is_propia" />}
                          label="Es Propia"
                          style={!isSmallScreen ? ({ marginLeft: '10px' }) : ({ marginTop: '10px' })}
                        />
                      </Grid>
                      {/* <Grid container item lg={12} xs={12} sm={12} spacing={1}> */}
                      <Grid item lg={12} md={12} xs={12} sm={12} style={{ display: 'flex' }}>
                        {mostrarRemitente ? (

                          <Grid item lg={6} md={6} xs={10} sm={10}>

                            <InputLabel id="Remitente">Remitente*</InputLabel>

                            <Autocomplete
                              disablePortal
                              value={remitente}
                              size={isSmallScreen ? "small" : "medium"}
                              noOptionsText={'Sin opciones'}
                              id="combo-box-demo"
                              options={desplegables}
                              PaperComponent={CustomPaper}
                              onChange={(e, value) => { if (value && value.cuit != undefined) { setRemitente(value.cuit.toString() + '-' + value.razon_social.toString()) } else { setRemitente('') } }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  type="text"
                                  variant="outlined"

                                />)}
                            />

                          </Grid>) : (
                          <Grid item lg={6} md={6} xs={10} sm={10}>
                            <TextField id="RemitenteInput" InputProps={{
                              classes: {
                                underline: classes.textFieldUnderline,
                                input: classes.textField,
                              },
                            }}
                              value={remitente}
                              onChange={e => { setRemitente(e.target.value) }
                              }
                              margin="normal"
                              placeholder="Nuevo Remitente"
                              variant="outlined"
                              label="Nuevo Remitente"
                              type="text"
                              fullWidth />
                          </Grid>
                        )}
                        <Grid item lg={2} md={2} xs={2} sm={2}>
                          <FormControlLabel
                            value="consgi"
                            control={<AddCircleIcon />}
                            className={classes.control}

                            label={!isSmallScreen ? "" : ""}
                            onClick={() => { setMostrarRemitente(value => !value); setRemitente('') }}
                          />


                        </Grid>
                      </Grid>
                      {/* </Grid> */}




                      {/* <Grid container item lg={12} xs={12} sm={12} spacing={1}> */}
                      <Grid item lg={12} md={12} xs={12} sm={12} style={{ display: 'flex' }}>
                        {mostrarConsig ? (
                          <Grid item lg={6} md={6} xs={10} sm={10}>
                            <InputLabel id="type">Consignatario</InputLabel>
                            <Autocomplete
                              disablePortal
                              size={isSmallScreen ? "small" : "medium"}
                              noOptionsText={'Sin opciones'}
                              id="combo-box-demo"
                              options={desplegables}
                              value={consignatario}
                              PaperComponent={CustomPaper}
                              onChange={(e, value) => { if (value && value.cuit != undefined) { setConsignatario(value.cuit.toString() + '-' + value.razon_social.toString()) } else { setConsignatario('') } }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  type="text"
                                  variant="outlined"

                                />)}
                            />
                          </Grid>) :
                          (<Grid item lg={6} md={6} xs={10} sm={10}>
                            <TextField id="ConsignatarioInput" InputProps={{
                              classes: {
                                underline: classes.textFieldUnderline,
                                input: classes.textField,
                              },
                            }}
                              value={consignatario}
                              onChange={e => setConsignatario(e.target.value)}
                              margin="normal"
                              placeholder="Nuevo Consignatario"
                              variant="outlined"
                              label="Nuevo Consignatario"
                              type="text"
                              fullWidth />
                          </Grid>)

                        }
                        <Grid item lg={2} md={2} xs={2} sm={2}>
                          <FormControlLabel
                            value="consgi"
                            control={<AddCircleIcon />}
                            labelPlacement="end"
                            label={!isSmallScreen ? "" : ""}
                            className={classes.control}
                            onClick={() => { setMostrarConsig(value => !value); setConsignatario('') }}
                          />
                        </Grid>
                      </Grid>

                      {/* </Grid> */}
                      {hide && (
                        <Grid container item lg={12} xs={12} sm={12} spacing={1}>
                          <Grid item lg={6} md={6} xs={10} sm={10}>
                            <InputLabel id="type">Contacto de Carga</InputLabel>
                            <Select
                              labelId="type"
                              variant="outlined"
                              id="ContactoDeCarga"
                              value={contactoDeCarga}
                              label="Contacto de Carga"
                              onChange={(e) => { setContactoDeCarga(e.target.value) }}
                              fullWidth
                              disabled={mostrarContacto}

                            >
                              {contactoDC.map(link => {
                                return (
                                  <MenuItem value={link.id}>
                                    {link.username} {link.last_name}
                                  </MenuItem>
                                )
                              })}


                            </Select>
                          </Grid>
                          <Grid item lg={2} md={2} xs={2} sm={2}>
                            <FormControlLabel
                              value="consgi"
                              control={<AddCircleIcon />}
                              className={classes.control}
                              label={!isSmallScreen ? "" : ""}
                              labelPlacement="end"
                              onClick={() => { setMostrarContacto(value => !value); setContactoDeCarga(''); }}
                            />
                          </Grid>
                          {mostrarContacto && (
                            <ContactoCarga
                              setOpenFeedbackContacto={setOpenFeedbackContacto}
                              setResponseErrorContacto={setResponseErrorContacto}
                              setContacto={setContactoDeCarga}
                            ></ContactoCarga>
                          )}

                        </Grid>)}


                      {!hide && (
                        <Grid item lg={12} xs={12} md={12} sm={12}>
                          <InputLabel id="type">Destino Comercial*</InputLabel>
                          <ToggleButtonGroup
                            value={alignmentDestino}
                            exclusive
                            size={isSmallScreen ? "small" : "medium"}
                            onChange={handleClickDestino}
                          >
                            {checkDestino.map(link => {
                              if (link != '*' && link != null) { return <ToggleButton value={link} aria-label="left aligned" borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }} >{link == 'Exportación UE' ? 'E. UE' : link == 'Exportacion Manufactura' ? 'Conserva' : link == 'Exportación TP' ? 'O. Países' : link}</ToggleButton> }

                            })}

                          </ToggleButtonGroup>
                        </Grid>)}
                      <Grid container item lg={12} md={12} xs={12} sm={12} style={{ display: 'flex' }} spacing={2}>
                        {UE && (
                          <Grid item lg={3} md={3} xs={6} sm={6}>
                            <InputLabel id="type">Cuota*</InputLabel>
                            <ToggleButtonGroup
                              value={alignmentCuota}
                              exclusive
                              size={isSmallScreen ? "small" : "medium"}
                              onChange={handleClickCuota}
                            >
                              {checkCuota.map(link => {
                                if (link != '*' && link != null) { return <ToggleButton value={link} aria-label="left aligned" borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }} >{link == 'No Hilton' ? 'NH' : link == 'Hilton' ? 'H' : link == 'Cuota 481' ? 'C481' : link}</ToggleButton> }

                              })}

                            </ToggleButtonGroup>
                          </Grid>)}
                        {!hide && (
                          <>
                            <Grid item lg={3} md={3} xs={6} sm={6}>
                              <InputLabel id="type">Raza</InputLabel>
                              <ToggleButtonGroup
                                value={alignmentRaza}
                                exclusive
                                size={isSmallScreen ? "small" : "medium"}
                                onChange={handleClickRaza}
                              >
                                {checkRaza.map(link => {
                                  if (link != '*' && link != null) { return <ToggleButton value={link} aria-label="left aligned" borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }} >{link}</ToggleButton> }

                                })}

                              </ToggleButtonGroup>
                            </Grid>
                          </>)}
                      </Grid>
                      <Grid container item lg={12} md={12} xs={12} sm={12} style={{ display: 'flex' }}>
                        {!hide && (
                          <>
                            <Grid item lg={3} md={3} xs={6} sm={6}>
                              <InputLabel id="type">Especie</InputLabel>
                              <ToggleButtonGroup
                                value={alignmentEspecie}
                                exclusive
                                size={isSmallScreen ? "small" : "medium"}
                                onChange={handleClickEspecie}
                              >
                                {checkEspecie.map(link => {
                                  if (link != '*') { return <ToggleButton value={link} aria-label="left aligned" borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }} >{link}</ToggleButton> }

                                })}

                              </ToggleButtonGroup>
                            </Grid>
                          </>)}
                      </Grid>

                      {!hide && (
                        <Grid item lg={12} md={12} xs={12} sm={12}>
                          <InputLabel id="type">Programa*</InputLabel>
                          <ToggleButtonGroup
                            value={alignmentPrograma}
                            exclusive
                            size={isSmallScreen ? "small" : "medium"}
                            onChange={handleClickPrograma}
                          >
                            {checkPrograma.map(link => {
                              if (link != '*') { return <ToggleButton value={link} aria-label="left aligned" borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }} >{(isSmallScreen && link == 'GRAIN FED') ? 'GF' : link}</ToggleButton> }

                            })}

                          </ToggleButtonGroup>
                          {verHQF &&
                            <FormControlLabel
                              value={hqf}
                              disabled={cuotaResult()}
                              onClick={() => { setHQF(value => !value) }}
                              control={<Checkbox checked={hqf} color="primary" />}
                              label="HQF"
                              style={!isSmallScreen ? ({ marginLeft: '10px' }) : ({ marginTop: '10px' })}
                            />
                          }
                          {showNop && (
                            <FormControlLabel
                              control={<Checkbox checked={isNop} onChange={(event) => { setIsNop(event.target.checked) }} name="is_nop" />}
                              label="Es NOP"
                              style={!isSmallScreen ? ({ marginLeft: '10px' }) : ({ marginTop: '10px' })}
                            />
                          )}

                        </Grid>)}
                      <Grid container item lg={12} md={12} xs={12} sm={12} style={{ display: 'flex' }} spacing={2}>
                        <Grid item lg={3} md={3} xs={6} sm={6}>
                          <TextField id="Cabezas" InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                            value={cabezasComp}
                            onWheel={event => event.target.blur()}
                            onChange={e => { setCabezasComp(e.target.value); handleChangeOrigin(e) }}
                            margin="normal"
                            name='cabezasComp'
                            placeholder="Cabezas"
                            variant="outlined"
                            label="Cabezas"
                            type="number"
                            fullWidth />
                        </Grid>
                        <Grid item lg={3} md={3} xs={6} sm={6}>
                          <TextField id="Peso" InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                            value={peso}
                            onWheel={event => event.target.blur()}
                            onChange={e => { setPeso(e.target.value); handleChangeOrigin(e) }}
                            margin="normal"
                            name='peso'
                            placeholder="Peso"
                            variant="outlined"
                            label="Peso"
                            type="number"
                            disabled={disabilitado('peso')}

                            fullWidth />

                        </Grid>
                      </Grid>
                      <Grid container item lg={12} md={12} xs={12} sm={12} style={{ display: 'flex' }} spacing={2}>
                        <Grid item lg={3} md={3} xs={6} sm={6} style={{ display: 'flex' }} spacing={2}>
                          <TextField id="Precio" InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                            value={precio}
                            onWheel={event => event.target.blur()}
                            onChange={e => { setPrecio(e.target.value); handleChangeOrigin(e) }}
                            margin="normal"
                            placeholder="Precio"
                            variant="outlined"
                            name='precio'
                            label="Precio"
                            type="number"
                            disabled={disabilitado('precio')}

                            fullWidth />
                          {tipoCompra === 'Faena' && (
                            <IconButton onClick={() => { setConRinde(!conRinde) }}><PercentIcon size='small' /></IconButton>
                          )}
                        </Grid>
                        {tipoCompra === "Invernada" && (
                          <Grid item lg={3} md={3} sm={12} xs={12}>
                            <TextField
                              onWheel={(e) => e.target.blur()}
                              id="descuento_comercial"
                              label="Descuento Comercial"
                              placeholder="Descuento Comercial"
                              size={isSmallScreen ? 'small' : 'medium'}
                              margin="normal"
                              value={descuentoComercial}
                              onChange={handleDescuentoComercialChange}
                              fullWidth
                              type="number"
                              variant="outlined"
                              min={0}
                              max={100}
                              endAdornment={<InputAdornment position="end">%</InputAdornment>}
                            />
                          </Grid>
                        )}
                        {tipoCompra === "Invernada" && (
                          <Grid item lg={3} md={3} sm={12} xs={12} spacing={2} style={{ display: 'flex', alignItems:'center' }}>
                            <FormControlLabel
                              value="tabla_descuento"
                              control={<AddCircleIcon />}
                              label="Tabla Descuento"
                              onClick={handleShowTablaDescuentoChange}
                            />
                          </Grid>)}
                        {(conRinde && tipoCompra === 'Faena') && (
                          <Grid item lg={3} md={3} xs={6} sm={6}>
                            <TextField
                              id="Rinde"
                              onWheel={event => event.target.blur()}
                              type="number"
                              min={50}
                              max={100}
                              InputProps={{
                                inputProps: { min: 50, max: 100 },
                                classes: {
                                  underline: classes.textFieldUnderline,
                                  input: classes.textField,
                                },
                              }}
                              value={rinde}
                              onChange={(e) => {
                                if (
                                  e.target.value < 50 ||
                                  (e.target.value > 65 && e.target.value < 100)
                                ) {
                                  setRindeError(true);
                                } else {
                                  setRindeError(false);
                                }

                                if (e.target.value > 100) {
                                  setRinde(100);
                                  setRindeError(false);
                                } else if (
                                  e.target.value > 65 &&
                                  e.target.value < 100
                                ) {
                                  setRinde(65);
                                  setRindeError(false);
                                } else if (
                                  e.target.value > 9 &&
                                  e.target.value < 50
                                ) {
                                  setRinde(50);
                                  setRindeError(false);
                                } else {
                                  setRinde(e.target.value);
                                }
                              }}
                              margin="normal"
                              placeholder="Rinde % $/KgG"
                              variant="outlined"
                              label="Rinde % $/KgG"
                              error={rinde < 10 ? true : false}
                              fullWidth
                            />
                          </Grid>)}
                        {conRinde && tipoCompra === "Faena" && rindeError && (
                          <Grid
                            item
                            lg={6}
                            md={12}
                            xs={12}
                            sm={12}
                            style={{ display: "flex" }}
                            spacing={2}
                          >
                            <Collapse in={rindeError} sx={{ marginTop: "15px" }}>
                              <Alert
                                variant="outlined"
                                severity="error"
                                sx={{ mb: 2, color: "inherit" }}
                              >
                                El rinde debe estar entre 50% y 65%, o ser 100% !
                              </Alert>
                            </Collapse>
                          </Grid>
                        )}
                      </Grid>
                      {(tipoCompra === "Invernada" && showTablaDescuento) && (
                        <Grid container item lg={12} md={12} xs={12} sm={12}>
                          <Grid item lg={9} md={12} xs={12} sm={12}>
                            <TablaDescuento setTablaId={setTablaId} tabla_id={tablaId} />
                          </Grid>
                        </Grid>
                      )}
                      {hide && (
                        <Grid container item lg={12} md={12} xs={12} sm={12}>
                          <Grid item lg={6} md={12} xs={12} sm={12}>
                            <InputLabel id="condicion_pago">Condicion de Pago</InputLabel>
                            <Select
                              labelId="type"
                              variant="outlined"
                              id="condicion_pago_select"
                              value={condicionPago}
                              label="Contacto de Carga*"
                              error={condicionPago == "" ? true : false}
                              onChange={(e) => {
                                setCondicionPago(e.target.value);
                              }}
                              fullWidth
                            >
                              {condicionesPago.map((value) => {
                                return (
                                  <MenuItem value={value.codigo}>
                                    {`${value.codigo} - ${value.descripcion}`}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </Grid>
                        </Grid>
                      )}
                      {!hide && (
                        <Grid container item lg={12} md={12} xs={12} sm={12} style={{ display: 'flex' }} spacing={2}>
                          <Grid item lg={3} md={3} xs={6} sm={6}>
                            <TextField id="Plazo"
                              InputProps={{
                                inputProps: { min: 0, max: 70 },
                                classes: {
                                  underline: classes.textFieldUnderline,
                                  input: classes.textField,
                                },
                              }}
                              value={plazo}
                              onWheel={event => event.target.blur()}
                              onChange={e => { if (e.target.value > 70) { setPlazo(70) } else { setPlazo(e.target.value) } }}
                              margin="normal"
                              placeholder="Plazo*"
                              variant="outlined"
                              label="Plazo*"
                              error={plazo == '' ? true : false}

                              type="number"
                              fullWidth />
                          </Grid>
                        </Grid>)}
                      {hide && (
                        <>

                          <Grid item lg={3} md={3} xs={6} sm={6}>
                            <InputLabel id="cuotaInvernada">Cuota Invernada</InputLabel>
                            <Select
                              labelId="cuotaInvernada"
                              variant="outlined"
                              id="cuotaInvernada"
                              value={cuotaInvernada}
                              label="cuotaInvernada"
                              onChange={(e) => {
                                setCuotaInvernada(e.target.value); setPlazoArray(Array(e.target.value))
                              }}
                              fullWidth
                            >
                              {[...Array(13)].map((e, i) => { if (i > 0) { return <MenuItem value={i}>{i}</MenuItem> } })}

                            </Select>
                          </Grid>
                          <Grid item lg={3} md={3} xs={6} sm={6}>
                            {[...Array(parseInt(cuotaInvernada))].map((e, i) => {
                              {
                                return <TextField id="Plazo"
                                  InputProps={{
                                    classes: {
                                      underline: classes.textFieldUnderline,
                                      input: classes.textField,
                                    },
                                  }}
                                  value={plazoArray[i]}
                                  onChange={e => onchangeInput(e, i)}
                                  margin="normal"
                                  placeholder={"Plazo" + (i + 1) + "*"}
                                  variant="outlined"
                                  label={"Plazo" + (i + 1) + "*"}
                                  type="text"
                                  fullWidth />
                              }

                            })}
                          </Grid>
                        </>


                      )}
                      <Grid container item lg={12} xs={12} md={12} sm={12} spacing={2}>
                        {mostrarComisionista ?
                          (<Grid item lg={5} md={5} xs={11} sm={11}>
                            <InputLabel id="Remitente">Comisionista</InputLabel>
                            <Autocomplete
                              disablePortal
                              value={comisionista}

                              noOptionsText={'Sin opciones'}
                              id="combo-box-demo"
                              options={desplegables}
                              PaperComponent={CustomPaper}
                              onChange={(e, value) => { if (value && value.cuit != undefined) { setComisionista(value.cuit.toString() + '-' + value.razon_social.toString()) } }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  type="text"
                                  variant="outlined"

                                />)}
                            />
                          </Grid>) : (
                            <Grid item lg={5} md={5} xs={11} sm={11}>
                              <TextField id="comisionistaInput" InputProps={{
                                classes: {
                                  underline: classes.textFieldUnderline,
                                  input: classes.textField,
                                },
                              }}
                                value={comisionista}
                                onChange={e => setComisionista(e.target.value)}
                                margin="normal"
                                placeholder="Nuevo Comisionista"
                                variant="outlined"
                                label="Nuevo Comisionista"
                                type="text"
                                fullWidth />
                            </Grid>)}
                        <Grid item lg={1} md={1} xs={1} sm={1}>
                          <FormControlLabel
                            value="consgi"
                            control={<AddCircleIcon />}
                            labelPlacement="end"
                            className={classes.control}
                            label={!isSmallScreen ? "" : ""}
                            onClick={() => { setMostrarComisionista(value => !value); setComisionista() }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3} xs={12} sm={12}>
                          <TextField id="Comisión" InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                            value={comision}
                            onChange={e => setComision(e.target.value)}
                            margin="normal"
                            onWheel={event => event.target.blur()}
                            placeholder="Comisión"
                            variant="outlined"
                            label="Comision"
                            type="number"
                            fullWidth />
                        </Grid>


                      </Grid>
                      {hide && (
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                          <Grid item lg={3} md={3} xs={12} sm={12}>
                            <TextField
                              id="Gastos adicionales"
                              onWheel={event => event.target.blur()}
                              margin="normal"
                              placeholder="Gastos adicionales"
                              variant="outlined"
                              value={gastosAdicionales}
                              label="Gastos adicionales"
                              type="number"
                              onChange={(e) => { setGastosAdicionales(e.target.value) }}
                              fullWidth
                            />
                          </Grid>

                        </Grid>
                      )}


                      {hide && (
                        <Grid container item lg={12} md={12} xs={12} sm={12} spacing={2}>
                          <Grid item lg={3} md={3} xs={12} sm={12}>
                            <TextField id="Desbaste" InputProps={{
                              classes: {
                                underline: classes.textFieldUnderline,
                                input: classes.textField,
                              },
                            }}
                              value={Desbaste}
                              onChange={handleChangeDesbaste}
                              margin="normal"
                              onWheel={event => event.target.blur()}
                              placeholder="Desbaste"
                              variant="outlined"
                              label="Desbaste"
                              type="number"
                              fullWidth />
                          </Grid>
                          <Grid container item lg={12} xs={12} sm={12} spacing={1}>
                            {mostrarFlete ? (<Grid item lg={3} md={3} xs={11} sm={11}>
                              <InputLabel id="Flete">Flete</InputLabel>
                              <Autocomplete
                                disablePortal
                                value={flete}

                                noOptionsText={'Sin opciones'}
                                id="combo-box-demo"
                                options={desplegables}
                                PaperComponent={CustomPaper}
                                onChange={(e, value) => { if (value && value.cuit != undefined) { setFlete(value.cuit.toString() + '-' + value.razon_social.toString()) } }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    type="text"
                                    variant="outlined"

                                  />)}
                              />

                            </Grid>) : (
                              <Grid item lg={3} md={3} xs={11} sm={11}>
                                <TextField id="fleteInput" InputProps={{
                                  classes: {
                                    underline: classes.textFieldUnderline,
                                    input: classes.textField,
                                  },
                                }}
                                  value={flete}
                                  onChange={e => setFlete(e.target.value)}
                                  margin="normal"
                                  placeholder="Nuevo Flete"
                                  variant="outlined"
                                  label="Nuevo Flete"
                                  type="text"
                                  fullWidth />
                              </Grid>


                            )}
                            <Grid item lg={1} md={1} xs={1} sm={1}>
                              <FormControlLabel
                                value="consgi"
                                control={<AddCircleIcon />}
                                className={classes.control}
                                label={!isSmallScreen ? "" : ""}
                                labelPlacement="end"
                                onClick={() => { setMostrarFlete(value => !value); setFlete('') }}
                              />
                            </Grid>
                            <Grid item lg={2} md={12} sm={12} xs={12}>
                              <TextField
                                id="Precio-Flete"
                                onWheel={event => event.target.blur()}
                                InputProps={{
                                  inputProps: { min: 0 },

                                  classes: {
                                    underline: classes.textFieldUnderline,
                                    input: classes.textField,
                                  },
                                }}
                                value={precioFlete}
                                //size={isSmallScreen ? 'small' : 'medium'}
                                onChange={(e) => { setPrecioFlete(e.target.value) }}
                                margin="normal"
                                placeholder="Precio Flete"
                                error={(flete.length > 0 && (precioFlete === 0 || precioFlete === "")) ? true : false}
                                variant="outlined"
                                label="Precio Flete"
                                type="number"
                                fullWidth
                              />
                            </Grid>
                          </Grid>

                          <Grid item lg={3} md={3} xs={12} sm={12}>
                            <TextField id="KilómetrosAproximados" InputProps={{
                              classes: {
                                underline: classes.textFieldUnderline,
                                input: classes.textField,
                              },
                            }}
                              value={km}
                              onChange={e => setKm(e.target.value)}
                              margin="normal"
                              placeholder="Kilómetros aproximados"
                              variant="outlined"
                              label="Kilómetros aproximados"
                              type="text"
                              fullWidth />
                          </Grid>
                          <Grid item lg={3} md={3} xs={6} sm={6}>
                            <TextField id="JaulaSimple" InputProps={{
                              classes: {
                                underline: classes.textFieldUnderline,
                                input: classes.textField,
                              },
                            }}
                              value={JaulaS}
                              onChange={e => setJaulaS(e.target.value)}
                              margin="normal"
                              onWheel={event => event.target.blur()}
                              placeholder="Jaula simple"
                              variant="outlined"
                              label="Jaula simple"
                              type="number"
                              fullWidth />
                          </Grid>
                          <Grid item lg={3} md={3} xs={6} sm={6}>
                            <TextField id="JaulaDoble" InputProps={{
                              classes: {
                                underline: classes.textFieldUnderline,
                                input: classes.textField,
                              },
                            }}
                              value={JaulaD}
                              onChange={e => setJaulaD(e.target.value)}
                              margin="normal"
                              onWheel={event => event.target.blur()}
                              placeholder="Jaula doble"
                              variant="outlined"
                              label="Jaula doble"
                              type="number"
                              fullWidth />
                          </Grid>


                        </Grid>)}


                      {false && (
                        <Grid item lg={6} xs={12} sm={6}>
                          <TextField
                            id="Procedencia"
                            InputProps={{
                              classes: {
                                underline: classes.textFieldUnderline,
                                input: classes.textField,
                              },
                            }}
                            value={procedencia}
                            onChange={e => setProcedencia(e.target.value)}
                            margin="normal"
                            variant="outlined"
                            label="Procedencia"
                            placeholder="procedencia"
                            type="text"
                            fullWidth />
                        </Grid>)}
                      <Grid container item spacing={1} lg={12} md={12} xs={12} sm={12}>
                        <Grid item lg={6} md={6} xs={12} sm={12}>
                          <TextField
                            id="observaciones"
                            InputProps={{
                              classes: {
                                underline: classes.textFieldUnderline,
                                input: classes.textField,
                              },
                            }}
                            value={observaciones}
                            onChange={e => setObservaciones(e.target.value)}
                            margin="normal"
                            variant="outlined"
                            label="Observaciones"
                            placeholder="observaciones"
                            type="text"
                            fullWidth />
                        </Grid>
                      </Grid>





                      <Grid alignContent='center' container item spacing={1} lg={6} md={6} xs={12} sm={12} style={{ display: 'flex' }}>
                        <Grid item lg={12} md={12} xs={12} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={enviarMailActualizacion}
                                  onClick={() => setEnviarMailActualizacion(!enviarMailActualizacion)}
                                  style={{
                                    color: theme.palette.primary.main,
                                  }}
                                />}
                              disabled={habilitarSwitch}

                              label="Enviar Mail de Actualizacion"
                              sx={{

                                '.Mui-disabled': {
                                  color: theme.palette.action.disabled
                                }

                              }}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12} sm={12}>
                          {responseLoading ? (
                            <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                              <CircularProgress size={26} className={classes.loginLoader} />
                            </div>
                          ) : (
                            <Button
                              fullWidth
                              onClick={handleUpdate}
                              /*   disabled={
                                  passwordValue.length === 0 ||
                                  passwordValue2.length === 0 ||
                                  email.length === 0
                                }  */
                              size="large"
                              variant="contained"
                              color="primary"
                              //component={Link} to={`/app/estadoCompras`}

                              className={classes.createAccountButton}
                            >
                              Guardar
                            </Button>
                          )}
                        </Grid>
                        <Grid item lg={6} md={6} xs={12} sm={12}>
                          <Button
                            className={classes.createAccountButton}
                            size="large"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={responseLoading}
                            onClick={handleCancelar}
                          //component={Link} to={`/app/estadoCompras`}
                          >

                            Cancelar
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Widget>
              )}
            </>
          )}
          <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
            <Alert elevation={6} variant="filled" onClose={handleClose} severity={openError ? "error" : "success"}>
              <AlertTitle>{openError ? "Ocurrio un error al actualizar la compra" : "Compra actualizada con éxito"}</AlertTitle>
            </Alert>
          </Snackbar>
          <Snackbar open={openFeedbackContacto} autoHideDuration={2000} onClose={handleCloseFeedbackContacto}>
            <Alert elevation={6} variant="filled" onClose={handleCloseFeedbackContacto} severity={responseErrorContacto ? "error" : "success"}>
              <AlertTitle>{responseErrorContacto ? "Oucrrio un error al crear el contacto de carga" : "Contacto  de carga creado con éxito"}</AlertTitle>
            </Alert>
          </Snackbar>
        </Grid>
      </Grid>
    </>
  );
}
export default EditCompra;