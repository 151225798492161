import { useEffect, useState, memo, useCallback, useContext, useRef, useMemo } from "react";
import UserService from "../../context/UserService";
import { ThemeProvider } from '@mui/material/styles';
import UserStateContext from "../../context/UserContext";
import Themes from "../../themes";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import useStyles from './styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton'
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import { Alert, AlertTitle } from "@mui/material";
import Snackbar from "@material-ui/core/Snackbar";

let nroDteRegex = new RegExp('^\\d{9}-\\d$');
let renspaRegex = new RegExp('^\\d{2}\\.\\d{3}\\.\\d{1}\\.\\d{5}\\/[A-Z0-9]{2}$');
let cuitRegex = new RegExp('^\\d{11}$');

const ModalAsociacionDtesInvernada = memo(({ open, setOpen, handleChangeData, idCompra, cargarData }) => {
    const { darkMode, user } = useContext(UserStateContext);
    const theme = darkMode ? Themes.default : Themes.light;
    const classes = useStyles();
    const canCreate = user.permissions.feedlot_can_create_dte_relations === true;
    const canDelete = user.permissions.feedlot_can_delete_dte_relations === true;

    const [isLoading, setIsLoading] = useState(true)
    const [responseError, setResponseError] = useState(null)
    const [dataCompraInvernada, setDataCompraInvernada] = useState(null)
    const [dtesPendientes, setDtesPendientes] = useState([])
    const [selectedDtes, setSelectedDtes] = useState([])
    const [loadingDtes, setLoadingDtes] = useState(false)
    const [sendingRequest, setSendingRequest] = useState(false)
    const [dtesRelations, setDtesRelations] = useState([])
    const [relacionToDelete, setRelationToDelete] = useState(null)
    const [deletingRelacion, setDeletingRelacion] = useState(false)
    const [originalDtes, setOriginalDtes] = useState([])
    const [erroresAsociacion, setErroresAsociacion] = useState({})
    const [showAsociacionError, setShowAsociacionError] = useState(false)
    // form para crear dte 
    const [displayForm, setDisplayForm] = useState(false)
    const [loadingFormResponse, setLoadingFormResponse] = useState(false)
    const [errors, setErrors] = useState({})
    const formRef = useRef(null);

    // feedback
    const [openFeedback, setOpenFeedback] = useState(false);
    const [responseFeedback, setResponseFeedback] = useState({});

    // each time selectedDtes change, we calculate the sum of cabezas
    const totalCabezasRecibidas = useMemo(() => {
        let fromSelected = selectedDtes.reduce((acc, dte) => {
            let dteData = originalDtes.find(item => item.nroDte === dte);
            return acc + dteData?.cantidadRecibidos;
        }, 0)
        let fromRelations = dtesRelations.reduce((acc, relation) => {
            return acc + relation?.dte_data?.cantidadRecibidos;
        }, 0)
        return fromSelected + fromRelations;
    }, [selectedDtes, originalDtes, dtesRelations])

    const totalCabezasEnviadas = useMemo(() => {
        let fromSelected = selectedDtes.reduce((acc, dte) => {
            let dteData = originalDtes.find(item => item.nroDte === dte);
            return acc + dteData?.cantidadEnviados;
        }, 0)
        let fromRelations = dtesRelations.reduce((acc, relation) => {
            return acc + relation?.dte_data?.cantidadEnviados;
        }, 0)
        return fromSelected + fromRelations;
    }, [selectedDtes, originalDtes, dtesRelations])

    // check if asociation is correct
    const isConsigOk = useMemo(() => {
        const distinctConsigInRelations = [...new Set(dtesRelations.map(relation => relation?.dte_data?.consigCuit))];
        const distinctConsigInSelected = [...new Set(selectedDtes.map(dte => originalDtes.find(item => item.nroDte === dte)?.cuit_consignatario))];
        // merge both arrays and remove duplicates
        const allConsig = [...new Set([...distinctConsigInRelations, ...distinctConsigInSelected])];
        return (allConsig.includes(null) && allConsig.length > 1) ? false : true;

    }, [selectedDtes, originalDtes, dtesRelations])

    // if the sum of cabezas is not equal to the total cabezas of the compra, we set an error
    useEffect(() => {
        let sumCabezasRecibidasCompra = dataCompraInvernada?.invernada?.cantidad_recepcionada + dataCompraInvernada?.invernada?.muertos;
        let sumCabezasCompradas = dataCompraInvernada?.cabezas_compradas;
        let newErroresAsociacion = {};

        if (totalCabezasRecibidas !== sumCabezasRecibidasCompra) {
            newErroresAsociacion.totalCabezasRecibidas = `La suma de cabezas recibidas de los dtes: ${totalCabezasRecibidas} no coincide con la cantidad de cabezas recibidas en destino + muertos: ${sumCabezasRecibidasCompra}`;
        } else {
            newErroresAsociacion.totalCabezasRecibidas = null;
        }

        //! 30/12/24 - No se valida mas la cantidad de cabezas enviadas
        // if (totalCabezasEnviadas !== sumCabezasCompradas) {
        //     newErroresAsociacion.totalCabezasEnviadas = `La suma de cabezas enviadas de los dtes: ${totalCabezasEnviadas} no coincide con la cantidad de cabezas compradas: ${sumCabezasCompradas}`;
        // } else {
        //     newErroresAsociacion.totalCabezasEnviadas = null;
        // }

        if (!isConsigOk) {
            newErroresAsociacion.consig = 'Si un dte tiene consignatario, todos los dtes deben tener consignatario';
        } else {
            newErroresAsociacion.consig = null;
        }
        setErroresAsociacion((prev) => ({ ...prev, ...newErroresAsociacion }));
    }, [totalCabezasEnviadas, totalCabezasRecibidas, dataCompraInvernada, isConsigOk])

    useEffect(() => {
        // si alguno de los values de erroresAsociacion es distinto de null, mostramos el error
        if ((Object.values(erroresAsociacion).some((val) => val !== null)) && selectedDtes.length > 0) {
            setShowAsociacionError(true)
        } else {
            setShowAsociacionError(false)
        }
    }, [erroresAsociacion, selectedDtes])

    const getInitialData = useCallback(async () => {
        setIsLoading(true)
        setResponseError(null)
        try {
            let responses = await Promise.all([UserService.getCompraInvernadaDetail(idCompra), UserService.getDtesInvernadaPendientesByCompraId(idCompra), UserService.getRelationsDtesInvernadaByCompraId(idCompra)])
            if (responses[0].status === 200 && responses[1].status === 200 && responses[2].status === 200) {
                setDataCompraInvernada(responses[0].data)
                setDtesPendientes(responses[1].data)
                setOriginalDtes(responses[1].data)
                setDtesRelations(responses[2].data.data)
            } else {
                throw new Error('Error al obtener los datos')
            }
        } catch (error) {
            setResponseError(error.message)
        } finally {
            setIsLoading(false)
        }
    }, [idCompra])

    const handleClose = useCallback((event, reason, isCancel=false) => {
        if (reason === 'backdropClick') {
            return
        }
        if (formRef.current !== null) {
            formRef.current.reset()
        }
        if (!isCancel) {
            cargarData()
        }
        setOpen(false)
        setDataCompraInvernada(null)
        setDtesPendientes([])
        setResponseError(null)
        setSelectedDtes([])
    }, [formRef])

    useEffect(() => {
        if (open) {
            getInitialData()
        }
    }, [idCompra, open])

    const createDteDescripcion = (dte) => {
        const nombre = dte.cuit_consignatario ? dte.nombre_consignatario : dte.nombre_remitente;
        const cuit = dte.cuit_consignatario ? dte.cuit_consignatario : dte.cuit_remitente;
        return `${dte.nroDte} - ${cuit} - ${nombre} - ${dte.fechaCarga} - Env: ${dte.cantidadEnviados} - Rec: ${dte.cantidadRecibidos} - ${dte.estado}`
    }

    const createRelationDescription = (relation) => {
        return `${relation.nroDte}`
    }

    const getPendingDtes = useCallback(async () => {
        setLoadingDtes(true)
        setResponseError(null)
        try {
            let response = await UserService.getDtesInvernadaPendientesByCompraId(idCompra)
            if (response.status === 200) {
                setDtesPendientes(response.data)
            } else {
                throw new Error('Error al obtener los dtes pendientes')
            }
        } catch (error) {
            setResponseError(error.message)
        } finally {
            setLoadingDtes(false)
        }
    }, [idCompra])

    const handleSubmit = useCallback(async () => {
        setSendingRequest(true)
        setResponseError(null)
        setDisplayForm(false)
        const data = {
            compraId: idCompra,
            dtes: selectedDtes
        }
        try {
            let response = await UserService.postRelationsDtesInvernada(data)
            if (response.status === 201) {
                handleClose()
                handleChangeData(idCompra, response.data.data, response.data.message)
            } else if (response.status === 500) {
                throw new Error('Error al asociar los dtes')
            } else {
                throw new Error(response?.data?.error)
            }
        } catch (error) {
            setResponseError(error.message)
        } finally {
            setSendingRequest(false)
        }
    }, [selectedDtes, idCompra, setOpen])

    const handleDeleteRelacion = useCallback(async (id) => {
        setRelationToDelete(id)
        setDeletingRelacion(true)
        setResponseError(null)
        try {
            let response = await UserService.deleteRelationDtesInvernada(id);
            if (response.status === 200) {
                let updatedRelations = dtesRelations.filter(relation => relation.id !== id)
                setDtesRelations(updatedRelations)
                getPendingDtes()
            } else {
                throw new Error('Error al eliminar la relacion')
            }
        } catch (error) {
            setResponseError(error.message)
        } finally {
            setDeletingRelacion(false)
            setRelationToDelete(null)
        }
    }, [dtesRelations])

    const handleShowForm = useCallback(() => {
        setDisplayForm(!displayForm)
        formRef.current.reset();
        setErrors({})
    }, [displayForm, formRef])

    const handleSubmitForm = useCallback(async (event) => {
        event.preventDefault();
        setOpenFeedback(false);
        setErrors({});
        let form = formRef.current;
        let nroDte = form.nroDte.value;
        let cabezasRecibidas = form.cabezasRecibidas.value;
        let renspaOrigen = form.renspaOrigen.value;
        let cuitRemitente = form.cuitRemitente.value;
        let nombreRemitente = form.nombreRemitente.value;
        let cuitConsignatario = form.cuitConsignatario.value;
        let nombreConsignatario = form.nombreConsignatario.value;
        let cabezasAmparadas = form.cabezasAmparadas.value;
        let cuitProductor = form.cuitProductor.value;
        let nombreProductor = form.nombreProductor.value;
        let thereIsConsig = cuitConsignatario !== '' || nombreConsignatario !== '';

        // validation
        let errors = {};
        let nroDteIsValid = nroDteRegex.test(nroDte);
        let renspaIsValid = renspaRegex.test(renspaOrigen);
        let cabezasIsValid = parseInt(cabezasRecibidas) > 0 && parseInt(cabezasRecibidas) < 9999;
        let cuitIsValid = cuitRegex.test(cuitRemitente);
        let nombreIsValid = nombreRemitente !== '';
        let cuitConsignatarioIsValid = thereIsConsig ? cuitRegex.test(cuitConsignatario) : true;
        let nombreConsignatarioIsValid = thereIsConsig ? nombreConsignatario !== '' : true;
        let cabezasAmparadasIsValid = parseInt(cabezasAmparadas) > 0 && parseInt(cabezasAmparadas) < 9999;
        let cuitProductorIsValid = cuitRegex.test(cuitProductor);
        let nombreProductorIsValid = nombreProductor !== '';

        if (!nroDteIsValid) {
            errors.nroDte = 'Formato invalido. Respete 123456789-0';
        }
        if (!renspaIsValid) {
            errors.renspaOrigen = 'Formato invalido. Respete 12.345.6.78901/A1';
        }
        if (!cabezasIsValid) {
            errors.cabezasRecibidas = 'Debe ser mayor a 0';
        }
        if (!cuitIsValid) {
            errors.cuitRemitente = 'Formato invalido. Respete 12345678901';
        }
        if (!nombreIsValid) {
            errors.nombreRemitente = 'Campo requerido';
        }
        if (!cuitConsignatarioIsValid) {
            errors.cuitConsignatario = 'Formato invalido. Respete 12345678901';
        }
        if (!nombreConsignatarioIsValid) {
            errors.nombreConsignatario = 'Campo requerido';
        }
        if (!cabezasAmparadasIsValid) {
            errors.cabezasAmparadas = 'Debe ser mayor a 0';
        }
        if (!cuitProductorIsValid) {
            errors.cuitProductor = 'Formato invalido. Respete 12345678901';
        }
        if (!nombreProductorIsValid) {
            errors.nombreProductor = 'Campo requerido';
        }
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        } else {
            setErrors({});
        }

        setLoadingFormResponse(true);
        try {
            let payload = {
                nroDte: nroDte,
                cantidadRecibidos: cabezasRecibidas,
                renspaOrigen: renspaOrigen,
                renspaDestino: dataCompraInvernada.campo_id?.renspa,
                fechaCarga: dataCompraInvernada.fecha_de_carga,
                cuitOrigen: cuitRemitente,
                titularOrigen: nombreRemitente,
                cantidadEnviados: cabezasAmparadas,
                productorCuit: cuitProductor,
                productorNombre: nombreProductor,

            }
            if (thereIsConsig) {
                payload.consigCuit = cuitConsignatario;
                payload.consigNombre = nombreConsignatario;
            }
            let response = await UserService.createDteInvernada(payload)
            if (response.status === 201) {
                setSelectedDtes((prev) => [...prev, nroDte])
                setOriginalDtes((prev) => [...prev, response.data.data])
                getPendingDtes()
                setResponseFeedback({ severity: 'success', message: 'Dte creado correctamente' })
                formRef.current.reset();
            } else if (response.status === 400) {
                throw new Error(response.data.error)
            } else {
                throw new Error('Error al crear el dte')
            }
        } catch (error) {
            if (error.response) {
                const errorMessages = Object.entries(error.response.data.error).map(([key, value]) => {
                    return `${key}: ${value[0]}`
                }).join(', ');
                console.log(errorMessages)
                setResponseFeedback({ severity: 'error', message: errorMessages })
            } else {
                setResponseFeedback({ severity: 'error', message: error.message })
            }
        } finally {
            setLoadingFormResponse(false);
            setOpenFeedback(true);
        }
    }, [getPendingDtes, idCompra, dataCompraInvernada])

    const handleCloseFeedback = useCallback(() => {
        setOpenFeedback(false);
    }, [])


    return (
        <ThemeProvider theme={theme}>
            <Modal
                open={open}
                onClose={handleClose}
                fullWidth={true}
            >
                <Box className={classes.modal}>
                    <div className={classes.title}>
                        <Typography variant="h3">Asociar Dtes a Invernada</Typography>
                    </div>
                    {isLoading && (
                        <div className={classes.loading}>
                            <CircularProgress size={20} />
                        </div>
                    )}
                    {(responseError && !isLoading) &&
                        <Typography variant="subtitle2" className={classes.error}>{responseError}</Typography>}
                    {(!responseError && !isLoading) && (
                        <section id="modal-content">
                            <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                            <div>
                                <Typography variant="subtitle2" className={classes.subtitle}>Compra de Invernada:</Typography>
                                <p>
                                    <b>Fecha de carga: </b> {dataCompraInvernada?.fecha_de_carga ? dataCompraInvernada.fecha_de_carga : "Pendiente"}<br />
                                    <b>Feedlot: </b> {dataCompraInvernada?.feedlot_id?.nombre} <br />
                                    <b>Campo: </b> {dataCompraInvernada?.campo_id?.nombre} <br />
                                    <b>Remitente: </b> {dataCompraInvernada?.remitente} <br />
                                    <b>Consignatario: </b> {dataCompraInvernada?.consignatario} <br />
                                    <b>Compradas: </b> {dataCompraInvernada?.cabezas_compradas} - Asociadas: <b className={erroresAsociacion?.totalCabezasEnviadas ? classes.error : classes.no_error}>{totalCabezasEnviadas}</b><br />
                                    <b>Cebezas recibidas: </b> {dataCompraInvernada?.invernada?.cantidad_recepcionada + dataCompraInvernada?.invernada?.muertos} - Asociadas: <b className={erroresAsociacion?.totalCabezasRecibidas ? classes.error : classes.no_error}>{totalCabezasRecibidas}</b><br />
                                </p>
                            </div>
                            {dtesRelations?.length > 0 && (
                                <>
                                    <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                                    <Typography variant="subtitle2" className={classes.subtitle}>Dtes asociados:</Typography>
                                    <List dense={true}>
                                        {dtesRelations?.map((relation) => (
                                            <ListItem key={relation.id}
                                                secondaryAction={
                                                    (deletingRelacion && relacionToDelete === relation.id) ? (<CircularProgress size={20} />) :
                                                        (<IconButton edge="end" aria-label="delete" onClick={() => { handleDeleteRelacion(relation.id) }} disabled={!canDelete}>
                                                            {canDelete ? (
                                                                <DeleteIcon color="primary" />) : (<></>)}
                                                        </IconButton>)
                                                }
                                            >
                                                <ListItemText
                                                    primary={createRelationDescription(relation)}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </>
                            )}
                            {showAsociacionError && (
                                <Alert severity="error" variant="filled">
                                    {Object.values(erroresAsociacion).map((error, index) => (
                                        <AlertTitle key={index}>{error}</AlertTitle>
                                    ))}
                                </Alert>
                            )}
                            <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                            {(dtesPendientes?.length > 0 && canCreate) ? (
                                <div>
                                    <Typography variant="subtitle2" className={classes.subtitle}>Dtes posibles de asociar:</Typography>
                                    <Autocomplete
                                        multiple
                                        id="select-compra-faena-label"
                                        options={dtesPendientes}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.nroDte}
                                        filterOptions={(options, { inputValue }) => {
                                            return options.filter(option => {
                                                const nombre = option.cuit_consignatario ? option.nombre_consignatario : option.nombre_remitente;
                                                const cuit = option.cuit_consignatario ? option.cuit_consignatario : option.cuit_remitente;
                                                return option.nroDte.toLowerCase().includes(inputValue.toLowerCase()) ||
                                                    (nombre && nombre.toLowerCase().includes(inputValue.toLowerCase())) ||
                                                    (cuit && cuit.toLowerCase().includes(inputValue.toLowerCase()))
                                            });
                                        }}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                <ListItemText primary={createDteDescripcion(option)} />
                                            </li>
                                        )}
                                        value={dtesPendientes.filter(dte => selectedDtes.includes(dte.nroDte))}
                                        onChange={(event, newValue) => {
                                            setSelectedDtes(newValue.map((item) => item.nroDte));
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                className={classes.select}
                                                //label="Seleccione los dtes a asociar"
                                                placeholder="Seleccione los dtes o escriba para filtrar"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </div>
                            ) : loadingDtes ? (
                                <div className={classes.loading}>
                                    <CircularProgress size={20} />
                                </div>
                            ) : (
                                <div>
                                    <Typography variant="subtitle2" className={classes.error}>No hay dtes para asociar</Typography>
                                </div>
                            )}
                            <div className={classes.create_dte}>
                                <Typography variant="caption text" className={classes.subtitle}>No esta en la lista?</Typography>
                                <Button onClick={handleShowForm} disabled={loadingFormResponse} variant='text'>{displayForm ? "Ocultar" : "Agregar"}</Button>
                            </div>
                            {displayForm && (
                                <form ref={formRef} onSubmit={handleSubmitForm} className={classes.form}>
                                    <div className={classes.form_inputs}>
                                        <div className={classes.form_rows}>
                                            <FormControl required error={errors.nroDte !== undefined}>
                                                <InputLabel htmlFor="nroDte" size="small">Nro Dte</InputLabel>
                                                <OutlinedInput id="nroDte" name="nroDte" size="small" type="text" />
                                                {errors.nroDte && <FormHelperText id="nroDte-error">{errors.nroDte}</FormHelperText>}
                                            </FormControl>
                                            <FormControl required error={errors.cuitRemitente !== undefined}>
                                                <InputLabel htmlFor="cuitRemitente" size="small">Cuit Remitente</InputLabel>
                                                <OutlinedInput id="cuitRemitente" name="cuitRemitente" size="small" type="text" />
                                                {errors.cuitRemitente && <FormHelperText id="cuitRemitente-error">{errors.cuitRemitente}</FormHelperText>}
                                            </FormControl>
                                            <FormControl required error={errors.nombreRemitente !== undefined}>
                                                <InputLabel htmlFor="nombreRemitente" size="small">R.S Remitente</InputLabel>
                                                <OutlinedInput id="nombreRemitente" name="nombreRemitente" size="small" type="text" />
                                                {errors.nombreRemitente && <FormHelperText id="nombreRemitente-error">{errors.nombreRemitente}</FormHelperText>}
                                            </FormControl>
                                            <FormControl error={errors.cuitConsignatario !== undefined}>
                                                <InputLabel htmlFor="cuitConsignatario" size="small">Cuit Consignatario</InputLabel>
                                                <OutlinedInput id="cuitConsignatario" name="cuitConsignatario" size="small" type="text" />
                                                {errors.cuitConsignatario && <FormHelperText id="cuitConsignatario-error">{errors.cuitConsignatario}</FormHelperText>}
                                            </FormControl>
                                            <FormControl error={errors.nombreConsignatario !== undefined}>
                                                <InputLabel htmlFor="nombreConsignatario" size="small">R.S Consignatario</InputLabel>
                                                <OutlinedInput id="nombreConsignatario" name="nombreConsignatario" size="small" type="text" />
                                                {errors.nombreConsignatario && <FormHelperText id="nombreConsignatario-error">{errors.nombreConsignatario}</FormHelperText>}
                                            </FormControl>
                                        </div>
                                        <div className={classes.form_rows}>
                                            <FormControl required error={errors.cuitProductor !== undefined}>
                                                <InputLabel htmlFor="cuitProductor" size="small">Cuit Productor</InputLabel>
                                                <OutlinedInput id="cuitProductor" name="cuitProductor" size="small" type="text" />
                                                {errors.cuitProductor && <FormHelperText id="cuitProductor-error">{errors.cuitProductor}</FormHelperText>}
                                            </FormControl>
                                            <FormControl required error={errors.nombreProductor !== undefined}>
                                                <InputLabel htmlFor="nombreProductor" size="small">R.S Productor</InputLabel>
                                                <OutlinedInput id="nombreProductor" name="nombreProductor" size="small" type="text" />
                                                {errors.nombreProductor && <FormHelperText id="nombreProductor-error">{errors.nombreProductor}</FormHelperText>}
                                            </FormControl>
                                            <FormControl required error={errors.cabezasAmparadas !== undefined}>
                                                <InputLabel htmlFor="cabezasAmparadas" size="small">Cabezas amparadas</InputLabel>
                                                <OutlinedInput id="cabezasAmparadas" name="cabezasAmparadas" size="small" type="number" />
                                                {errors.cabezasAmparadas && <FormHelperText id="cabezasAmparadas-error">{errors.cabezasAmparadas}</FormHelperText>}
                                            </FormControl>
                                            <FormControl required error={errors.cabezasRecibidas !== undefined}>
                                                <InputLabel htmlFor="cabezasRecibidas" size="small">Cabezas recibidas</InputLabel>
                                                <OutlinedInput id="cabezasRecibidas" name="cabezasRecibidas" size="small" type="number" />
                                                {errors.cabezasRecibidas && <FormHelperText id="cabezasRecibidas-error">{errors.cabezasRecibidas}</FormHelperText>}
                                            </FormControl>
                                            <FormControl required error={errors.renspaOrigen !== undefined}>
                                                <InputLabel htmlFor="renspaOrigen" size="small">Renspa origen</InputLabel>
                                                <OutlinedInput id="renspaOrigen" name="renspaOrigen" size="small" type="text" />
                                                {errors.renspaOrigen && <FormHelperText id="renspaOrigen-error">{errors.renspaOrigen}</FormHelperText>}
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className={classes.form_button}>
                                        <LoadingButton loading={loadingFormResponse} type="submit" variant="text" color="primary" size="small">Crear Dte</LoadingButton>
                                    </div>
                                </form>
                            )}
                        </section>)}
                    <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                    <section id="actions" className={classes.actions}>
                        <Button type="reset" onClick={(reason,event) => handleClose(event,reason, true)} style={{ marginRight: '3px' }}>Cancelar</Button>
                        <LoadingButton loading={sendingRequest} variant="contained" color="primary" onClick={handleSubmit} disabled={selectedDtes.length === 0 || sendingRequest || !canCreate || showAsociacionError}>Asociar</LoadingButton>
                    </section>
                </Box>
            </Modal>
            <Snackbar
                open={openFeedback}
                autoHideDuration={3000}
                onClose={handleCloseFeedback}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    severity={responseFeedback?.severity}
                >
                    <AlertTitle>
                        {responseFeedback?.message}
                    </AlertTitle>
                </Alert>
            </Snackbar>
        </ThemeProvider>
    )
})

export default ModalAsociacionDtesInvernada;