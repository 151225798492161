import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Paper } from '@material-ui/core';
import InputAdornment from '@mui/material/InputAdornment';
import UserService from '../../context/UserService';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useTheme } from "@material-ui/styles";
import { ThemeProvider } from "@mui/material/styles";


const TablaDescuento = ({ tabla_id, setTablaId }) => {
    const theme = useTheme();
    const [formData, setFormData] = useState({
        kg_desde: '',
        kg_hasta: '',
        descuento: ''
    });
    const [oldData, setOldData] = useState({
        kg_desde: '',
        kg_hasta: '',
        descuento: ''
    })
    const [disable, setDisable] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (tabla_id) {
            UserService.getTablaDescuento(tabla_id)
                .then(response => {
                    setFormData(response.data.data);
                    setOldData(response.data.data);
                })
                .catch(error => {
                    setError('No se pudo cargar la tabla de descuento');
                });
        }
    }, [tabla_id]);

    const handleChange = (e) => {
        if (error) {
            setError(null);
        }
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: parseInt(value)
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.kg_hasta <= formData.kg_desde) {
            setError('El kg hasta debe ser mayor al kg desde');
            return;
        }
        if (formData.descuento <= 0 || formData.kg_desde <= 0 || formData.kg_hasta <= 0) {
            setError('Los campos no pueden ser menor a 0');
            return;
        }
        if (formData.descuento == 0) {
            setError('El descuento no puede ser 0');
            return;
        }
        if (tabla_id) {
            UserService.updateTablaDescuento(tabla_id, formData)
                .then(response => {
                    setOldData(formData);
                })
                .catch(error => {
                    setError('No se pudo actualizar la tabla de descuento');
                });
        } else {
            UserService.createTablaDescuento(formData)
                .then(response => {
                    setOldData(formData);
                    setTablaId(response.data.data.id);
                })
                .catch(error => {
                    setError('No se pudo crear la tabla de descuento');
                });
        }
    };

    const handleDelete = () => {
        if (tabla_id) {
            UserService.deleteTablaDescuento(tabla_id)
                .then(response => {
                    setTablaId(null);
                    setFormData({
                        kg_desde: '',
                        kg_hasta: '',
                        descuento: ''
                    });
                    setOldData({
                        kg_desde: '',
                        kg_hasta: '',
                        descuento: ''
                    });
                })
                .catch(error => {
                    setError('No se pudo borrar la tabla de descuento');
                });
        }
    };

    useEffect(() => {
        console.log(oldData, formData);
        if (oldData.kg_desde !== formData.kg_desde ||
            oldData.kg_hasta !== formData.kg_hasta ||
            oldData.descuento !== formData.descuento) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    }, [formData, oldData]);

    return (
        <ThemeProvider theme={theme}>
            <Stack sx={{ width: '100%' }} spacing={2}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                onWheel={(e) => e.target.blur()}
                                label="Kg Desde"
                                name="kg_desde"
                                value={formData.kg_desde}
                                onChange={handleChange}
                                fullWidth
                                type="number"
                                variant="outlined"
                                required
                                min={0}
                                endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                onWheel={(e) => e.target.blur()}
                                label="Kg Hasta"
                                name="kg_hasta"
                                value={formData.kg_hasta}
                                onChange={handleChange}
                                fullWidth
                                type="number"
                                variant="outlined"
                                required
                                min={0}
                                endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                onWheel={(e) => e.target.blur()}
                                label="Descuento"
                                name="descuento"
                                value={formData.descuento}
                                onChange={handleChange}
                                fullWidth
                                type="number"
                                variant="outlined"
                                required
                                min={0}
                                endAdornment={<InputAdornment position="end">$/kg</InputAdornment>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Button type="submit" variant="contained" color="primary" size="small" disabled={disable}>
                                {tabla_id ? 'Actualizar' : 'Crear'}
                            </Button>
                            {tabla_id && (
                                <Button
                                    variant="outlined"
                                    onClick={handleDelete}
                                    style={{ marginLeft: 16 }}
                                >
                                    Borrar
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </form>
                {error && <Alert variant="outlined" severity="error">{error}</Alert>}
            </Stack>
        </ThemeProvider>
    );
};

export default TablaDescuento;