import { useEffect, useState, useCallback, useMemo, useRef, memo, useContext } from "react"
import moment from "moment"
import MUIDataTable from "mui-datatables";
import ResponsiveDatePickers from "../../components/dateRange/datePicker";
import UserService from "../../context/UserService";
import { CircularProgress, Button, Typography, Grid, Checkbox, Snackbar, FormControlLabel, FormControl } from "@material-ui/core";
import { Alert } from "@mui/material";
import Box from '@material-ui/core/Box';
import UserStateContext from "../../context/UserContext";

const TIMER_SECONDS = 60*5; // 5 minutos

const MarcasMarbling = memo(() => {
    const [fecha, setFecha] = useState(moment().format('YYYY-MM-DD'));
    const [loading, setLoading] = useState(false);
    const [listaResponse, setListaResponse] = useState({ data: null, error: null, success: null });
    const [error, setError] = useState(false);
    const [marcaStatus, setMarcaStatus] = useState({ success: null, message: null });
    const [open, setOpen] = useState(false);
    const [autoRefresh, setAutoRefresh] = useState(false);
    const [countdown, setCountdown] = useState(TIMER_SECONDS);
    const ref = useRef(null);
    const countdownTimerRef = useRef(null);
    const { user } = useContext(UserStateContext);
    const permissions = user.permissions;
    const canCreateMarcas = permissions.marcas_marbling_can_create_marcas === true;
    const canDeleteMarcas = permissions.marcas_marbling_can_delete_marcas === true;

    const centeredColumn = useMemo(() => (
        {
            setCellProps: () => ({ style: { textAlign: 'center' } }),
        }
    ), [])

    const formatCountdown = useCallback((countdown) => {
        let minutes = Math.floor(countdown / 60);
        let seconds = countdown % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }, [])

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [])

    const handleRefreshChange = useCallback((event) => {
        setAutoRefresh(event.target.checked);
    }, [])

    const handleCheck = useCallback(async (event) => {
        let index = parseInt(event.target.id);
        let checked = event.target.checked;
        let row_data = { ...listaResponse?.data[index] };

        // Si la marca ya existe, se elimina, si no, se crea
        try {
            if (checked) {
                let data = {
                    tropa: row_data?.tropa,
                    fecha: fecha,
                    cabezas: row_data?.cabezas,
                }
                let response = await UserService.createMarcasMarbling(data);
                if (response.status === 201) {
                    setMarcaStatus({ success: true, message:`Marca ${row_data?.tropa} - ${fecha} creada` });
                    row_data.marca_id = response.data.data;
                } else {
                    throw new Error('Error al crear la marca');
                }
            } else {
                let response = await UserService.deleteMarcasMarbling(row_data?.marca_id);
                if (response.status === 204) {
                    setMarcaStatus({ success: true, message: `Marca ${row_data?.tropa} - ${fecha} eliminada` });
                    row_data.marca_id = null;
                } else {
                    throw new Error('Error al eliminar la marca');
                }
            }
        } catch (error) {
            setMarcaStatus({ success: false, message: error?.response?.data?.error || error.message });
        } finally {
            setOpen(true);
            setListaResponse((prevState) => {
                let newData = prevState.data;
                newData[index] = row_data;
                return { ...prevState, data: newData };
            })
        }

    }, [listaResponse])

    const columns = useMemo(() => ([
        {
            name: 'tropa',
            label: 'Tropa',
            options: {
                ...centeredColumn
            }
        },
        {
            name: 'fecha_rec',
            label: 'Fecha Rec',
            options: {
                ...centeredColumn
            }
        },
        {
            name: 'usuario',
            label: 'Usuario',
            options: {
                ...centeredColumn
            }
        },
        {
            name: 'raza',
            label: 'Raza',
            options: {
                ...centeredColumn
            }
        },
        {
            name: 'especies',
            label: 'Especies',
            options: {
                ...centeredColumn
            }
        },
        {
            name: 'cabezas',
            label: 'Cabezas',
            options: {
                ...centeredColumn
            }
        },
        {
            name: 'destino',
            label: 'Destino',
        },
        {
            name: 'cuota',
            label: 'Cuota',
            options: {
                ...centeredColumn
            }
        },
        {
            name: 'programa',
            label: 'Programa',
        },
        {
            name: 'tipo',
            label: 'Tipo',
            options: {
                ...centeredColumn
            }
        },
        {
            name: 'marca_id',
            label: 'Medir',
            options: {
                ...centeredColumn,
                customBodyRenderLite: (dataIndex) => {
                    let isChecked = listaResponse?.data[dataIndex]?.marca_id !== null;
                    return <Checkbox color="primary" size="small" id={dataIndex} onChange={handleCheck} checked={isChecked} disabled={!(canCreateMarcas && canDeleteMarcas)}/>
                }
            }
        }
    ]), [listaResponse])

    const scrollToRef = useCallback(() => {
        setTimeout(() => {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }, 1000);
    }, [ref])

    const getLista = useCallback(async (fecha) => {
        setLoading(true);
        setError(false);
        setListaResponse({ data: null, error: null, success: null });
        let response;
        try {
            response = await UserService.getMarcasMarbling(fecha);
            if (response.status === 200) {
                setListaResponse(response.data);
                if (response.data.error) {
                    throw new Error(response.data.error);
                } else {
                    scrollToRef();
                }
            } else {
                throw new Error('Error al obtener los datos');
            }
        } catch (error) {
            setListaResponse({ data: null, error: error?.response?.data?.error || error.message, success: false });
            setError(true);
        } finally {
            setLoading(false);
        }
    }, [fecha])

    const setDate = useCallback((event) => {
        setFecha(moment(event).format("YYYY-MM-DD"))
    })

    const onSearch = useCallback(() => {
        getLista(fecha);
    }, [fecha])

    useEffect(() => {
        getLista(fecha);
    }, [])

    
    useEffect(() => {
        if (autoRefresh) {
            countdownTimerRef.current = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown > 0 ? prevCountdown - 1 : TIMER_SECONDS);
            }, 1000);
        } else {
            clearInterval(countdownTimerRef.current);
            setCountdown(TIMER_SECONDS);
        }
        return () => {
            clearInterval(countdownTimerRef.current);
        }
    }, [autoRefresh, fecha, getLista])

    useEffect(() => {
        if (autoRefresh && countdown === 0) {
            getLista(fecha);
        }
    }, [autoRefresh, countdown])

    return (
        <>
            <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box flexWrap="wrap" display="flex" alignItems="center" flexDirection="row" style={{ gap: 10 }}>
                        <ResponsiveDatePickers
                            value={fecha}
                            inputFormat='DD/MM/YYYY'
                            setValorFecha={setDate}
                        />
                        {loading ? <CircularProgress size={22} /> : <Button variant="contained" color="primary" onClick={onSearch}>Buscar</Button>}
                        <FormControlLabel 
                            control={<Checkbox checked={autoRefresh} onChange={handleRefreshChange}/>} 
                            label={autoRefresh ? `Refrescando en ${formatCountdown(countdown)}` :"Refrescar automáticamente"}/>
                        {error && <Typography variant="body1" color="error">{listaResponse.error}</Typography>}
                    </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} ref={ref}>
                    {(listaResponse.data && !listaResponse.error) && <MUIDataTable
                        data={listaResponse.data}
                        columns={columns}
                        options={{
                            selectableRows: false,
                            print: false,
                            download: true,
                            filter: false,
                            viewColumns: false,
                            pagination: false,
                            search: true,
                            responsive: 'standard',
                            sort: false
                        }}
                    />}
                </Grid>
            </Grid>
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert elevation={6} variant="filled" severity={marcaStatus.success ? 'success' : 'error'}>{marcaStatus.message}</Alert>
            </Snackbar>
        </>
    )
})

export default MarcasMarbling;