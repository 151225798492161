import React, { useContext, useState } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";

import Themes from "../themes";
// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";
import Login from "../pages/login";
/* import Editar from "../pages/editar/Edit";
import Tables from "../pages/tables/Tables";  */
import ResponsiveDialog from "./informe/Informe";
import CambiarPass from "../pages/cambiarPass/cambiarPass";
// context
import UserStateContext from "../context/UserContext";

import { UserProvider } from "../context/UserContext";
import { useEffect } from "react";
import CambioStatusCompra from "../pages/cambioStatusCompra/CambioStatusCompra";

export default function App() {
  // global
  const { darkMode } = useContext(UserStateContext);
  //var rol = localStorage.getItem('permissions')

  //const landing = (rol == 1 || rol == 2 || rol == 3) ? '/app/estadoCompras' : (rol == 5  || rol == 10 || rol == 6 || rol == 3) ? "/app/dashboard" : (rol == 4 || rol == 9) ? "/app/romaneo" : "/app/gestionDoc"
  var landing = localStorage.getItem('landing_page') 
  //console.log(landing)
  //const [dark, setdark] =  useState(()=>darkMode==true?Themes.default:Themes.light);


  return (
    <HashRouter>
      <UserProvider value={darkMode}>
        <UserStateContext.Consumer>
          {({ darkMode }) => (
            <ThemeProvider theme={darkMode == true ? Themes.default : Themes.light}>
              <CssBaseline />
              <Switch>
                <PrivateRoute exact path="/" render={() => <Redirect to={landing} />} />
                <PrivateRoute exact path="/app" render={() => <Redirect to={landing} />} />
                <PrivateRoute exact path="/app/home" render={() => <Redirect to={landing} />} />
                <PrivateRoute path="/app" component={Layout} />
                <PrivateRoute path="/app/tables" component={Layout} />
                < PrivateRoute path="/app/tables" render={() => <Redirect to="/app/tables" />} />
                <PrivateRoute path="/app/users" render={() => <Redirect to="/app/users" />} />
                <PrivateRoute path="/app/profile" render={() => <Redirect to="/app/profile" />} />
                {/* <PrivateRoute path="/app/compras" render={() => <Redirect to="/app/compras" />} /> */}
                <PrivateRoute path="/app/nuevasCompras" render={() => <Redirect to="/app/nuevasCompras" />} />
                <PrivateRoute path="/app/editCompra" render={() => <Redirect to="/app/editCompra" />} />
                <PrivateRoute path="/app/usuariosDeCarga" render={() => <Redirect to="/app/usuariosDeCarga" />} />
                <PrivateRoute path="/app/nuevoUsuariosDeCarga" render={() => <Redirect to="/app/nuevoUsuariosDeCarga" />} />
                <PrivateRoute path="/app/editUsuariosDeCarga" render={() => <Redirect to="/app/editUsuariosDeCarga" />} />
                <PrivateRoute path="/app/dte" render={() => <Redirect to="/app/dte" />} />
                <PrivateRoute path="/app/estadoCompras" render={() => <Redirect to="/app/estadoCompras" />} />
                <PrivateRoute path="/app/checkList" render={() => <Redirect to="/app/checkList" />} />
                <PrivateRoute path="/app/gestionDoc" render={() => <Redirect to="/app/gestionDoc" />} />
                <PrivateRoute path="/app/nuevoDoc" render={() => <Redirect to="/app/nuevoDoc" />} />
                <PrivateRoute path="/app/infGeneral" render={() => <Redirect to="/app/infGeneral" />} />
                <PrivateRoute path="/app/dashboard" render={() => <Redirect to="/app/dashboard" />} />
                <PrivateRoute path="/app/romaneo" render={() => <Redirect to="/app/romaneo" />} />
                <PrivateRoute path="/app/Feedlot" render={() => <Redirect to="/app/Feedlot" />} />
                <PrivateRoute path="/app/Cashflow" render={() => <Redirect to="/app/Cashflow" />} />
                <PrivateRoute path="/app/listaMatanza" render={() => <Redirect to="/app/listaMatanza" />} />
                <PrivateRoute path="/app/marcasMarbling" render={() => <Redirect to="/app/marcasMarbling" />} />


                <Route path="/login" component={Login} />
                <Route path="/informe" component={ResponsiveDialog} />
                <Route path="/password" component={CambiarPass} />
                <PublicRoute path="/statusCompra" component={CambioStatusCompra} />

                <PrivateRoute path="/edit" component={Layout} />
                <PrivateRoute path="/edit/:id" component={Layout} />



                <Route component={Error} />
              </Switch>
            </ThemeProvider>
          )}
        </UserStateContext.Consumer>
      </UserProvider>
    </HashRouter>
  );

  // #######################################################################

  function PrivateRoute({ children, ...rest }) {
    const { user } = useContext(UserStateContext);

    if (!user) {
      return <Route {...rest}>{!user ? <Redirect to="/login" /> : user && user.last_login == 'None' ? <Redirect to="/password" /> :children}</Route>;
    } else {
      let pathname = rest.location.pathname;
      let isAuthorized = IsAuthorized(user, pathname);
      return <Route {...rest}>{!user ? <Redirect to="/login" /> : user && user.last_login == 'None' ? <Redirect to="/password" /> : !isAuthorized ? <Redirect to={landing} /> :children}</Route>;
    }
    //return <Route {...rest}>{!user ? <Redirect to="/login" /> :  children}</Route>

    //return <Route {...rest}> <Redirect to="/login" /> </Route>;

  }
  function IsAuthorized(user, path) {

    // Dashboard
    if (!user.permissions.dashboard_can_view && path == "/app/dashboard") {
      return false;
    } 
    // Usuarios
    else if (!user.permissions.usuarios_can_view && path == "/app/tables") {
      return false;
    }
    // Compras
    else if (!user.permissions.compras_can_view && path == "/app/estadoCompras") {
      return false;
    }
    // Stock Corrales
    else if (!user.permissions.stock_can_view && path == "/app/stock") {
      return false;
    }
    // Faena
    else if (!user.permissions.faena_can_view && path == "/app/romaneo") {
      return false;
    }
    // Dte
    else if (!user.permissions.dte_can_view && path == '/app/dte') {
      return false;
    }
    // Checklist
    else if (!user.permissions.checklist_can_view && path == '/app/checkList') {
      return false;
    }
    // Gestion documental
    else if (!user.permissions.gestionDoc_can_view && path == '/app/gestionDoc') {
      return false;
    }
    // Feedlot
    else if (!user.permissions.feedlot_can_view && path == '/app/Feedlot') {
      return false;
    }
    // Cashflow
    else if (!user.permissions.cashflow_can_view && path == '/app/Cashflow') {
      return false;
    }
    // lista matanza
    else if (!user.permissions.lista_matanza_can_view && path == '/app/listaMatanza') {
      return false;
    }
    else if (!user.permissions.marcas_marbling_can_view && path == '/app/marcasMarbling') {
      return false;
    }
    else {
      return true;
    }
  }


  function PublicRoute({ component, ...rest }) {

    return (
      <Route
        {...rest}
        render={props =>
          React.createElement(component, props)

        }
      />
    );
  }
}
