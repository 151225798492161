import React, { useState, useEffect, useContext } from "react";
import {
  TableFooter,
  TableRow,
  Box,
  useMediaQuery
} from "@material-ui/core";
import {
  Grid, Button, ButtonBaseTypeMap, MuiThemeProvider, TableCell,

  createMuiTheme, CircularProgress, Typography, Fade, InputLabel
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";/* import MUIDataTable from "mui-datatables";
import MenuItem from '@mui/material/MenuItem';  
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton'; */
// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
// 
import { LocalizationProvider } from "@mui/x-date-pickers";

import ResponsiveDatePickers from "../../components/dateRange/datePicker";
import MUIDataTable from "mui-datatables";
import { optionsNormal } from "../../components/variables/variables";
import UserService from "../../context/UserService";
import { fakeData } from "../../stock_web";
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import moment from "moment";
import { el } from "date-fns/locale";
import { isLeapYear } from "date-fns";
import clsx from 'clsx';
import { darken, lighten } from '@mui/material/styles';
import CustomToolbar from "./customprint";
import { signOut } from "../../context/UserContext";
import UserStateContext from "../../context/UserContext";

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  },
  formButtons: {
    width: "50%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonSubmit: {
    padding: '15px',
    fontSize: '1.2em',
    marginTop: '17px'

  },

  footerCell: {
    backgroundColor: theme.palette.primary.main,
    borderBottom: "none",
    color: "white",
    fontSize: 14,
    fontWeight: "bolder"
  },
  totales: {
    '& td': {
      backgroundColor: theme.palette.totals.total,
      // color: 'white',
      // border: '0px',
      // borderButtom: '2px solid ' + theme.palette.tables.tableBorderColor,
      // borderTop: '2px solid ' + theme.palette.tables.tableBorderColor,
      fontSize: theme.typography.fontSize + 1,
      fontWeight: 'bold'
    },
  },
  subtotal: {
    '& td': {
      backgroundColor: theme.palette.totals.subtotal,
      // color: 'white',
      // border: '0px',
      // borderButtom: '2px solid ' + theme.palette.tables.tableBorderColor,
      // borderTop: '2px solid ' + theme.palette.tables.tableBorderColor,
      //fontSize: 15,
      fontWeight: 'bold',
    },
  },
  centerContent: {
    textAlign: 'center',
    justifyContent: 'center'
  }

}))
const breakpoints = createBreakpoints({})
const getMuiTheme = () =>
  createMuiTheme({

    overrides: {
      MUIDataTableBodyCell: {
        root: {
          minWidth: 'max-content',
          minHeight: '1rem',
          Width: 'auto',
          padding: '0px 3px 0px 3px',
          alignSelf: 'center'
        }
      },
      MuiTableRow: {
        root:
        {
          verticalAlign: 'baseline'
        }
      },
      MuiTableCell: {
        head: {
          fontWeight: "bold"
        }
      },
      MuiPaper: {
        root: {
          color: '#fff',
          backgroundColor: 'rgb(35, 35, 45)'
        },
      },
      MuiTableCell: {

        head: {
          fontWeight: "bold",
          color: '#fff',
          backgroundColor: 'rgb(35, 35, 45)'
        },
        body: {
          color: '#fff'
        }
      },
      MuiIconButton: {
        root: { color: '#fff', }
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: 'rgb(35, 35, 45)'

        },
      },
      MuiTablePagination: {
        toolbar: {
          color: 'white'
        }

      },

      MUIDataTableToolbar: {

        filterPaper: {

          [breakpoints.down("xs")]: {
            maxWidth: '100%'
          }
        },
        actions: {
          display: "flex",
          flex: "initial",
          // move all icons to the right
          "& > span, & > button": {
            order: 99
          },
          // target the custom toolbar icon
          "& > span:last-child, & > button:last-child": {
            order: 1
          },
          // target any icon
          "& > span:nth-child(4), & > button:nth-child(4)": {
            order: 2
          }
        }
      }
    },
    shadows: ['none']


  });

export default function Stock(props) {
  const [fecha, setFecha] = useState(moment().format("YYYY-MM-DD"));
  const classes = useStyles();
  var isSmallScreen = useMediaQuery((theme) => {
    return theme.breakpoints.down('md')
  })
  const { user } = useContext(UserStateContext);
  const type = user.type;
  
  const [largoStockActual, setLargoStockActual] = useState(0);
  const [largoStockInicial, setLargoStockInicial] = useState(0);

  const [valorTablaActual, setValorTablaActual] = useState([])
  const [valorTablaInicio, setValorTablaInicio,] = useState([])

  const [horaListaMatanza, setHoraListaMatanza] = useState(null)

  const FechaDelDia = moment().format("DD/MM/YY");
  var tropa = localStorage.getItem('filterList')
  //manejo de tiempos del servidor
  var [errorServidor, setErrorServidor] = useState(null);
  var [isLoading2, setIsLoading2] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const columns = ["Usuario", "Tropa", "Recepcion","Cuota", "Especies", "Remitente", "Consignatario", "Cabezas", "Kilos", "kilos Promedio", "Corral", "Programa"]

  var optionsFooter = {
    customHeadRender: () => null,
    filter: false,
    responsive: 'standard',
    search: false,
    viewColumns: false,
    print: false,
    download: true,
    sort: false,
    pagination: false,
    selectableRowsHideCheckboxes: true,
    customToolbar: (displayData) => {
      return (
        <>
          <CustomToolbar data={valorTablaActual} columns={columns} titulo='Tabla_Actual'></CustomToolbar>
        </>
      );
    },


    onChangeRowsPerPage(numberOfRows) {
      setRowsPerPage(numberOfRows);
    },
    onChangePage(page) {
      setPage(page);
    },
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        className: clsx({
          [classes.totales]: row[1] == undefined && !row[0].includes('Subtotal'),
          [classes.subtotal]: row[0].includes('Subtotal'),
        }),
      };
    },
    setCellProps: (cellValue, rowIndex, columnIndex) => {
      if ([1, 2, 3, 6, 7, 8, 9].includes(columnIndex)) {

        return { className: classes.centerContent };
      }
      return {};
    }

  }

  var optionsFooter2 = {
    customHeadRender: () => null,
    filter: false,
    responsive: 'standard',
    search: false,
    fixedHeader: true,
    tableBodyHeight: largoStockActual >= 33 ? '700px' : (largoStockActual * 22 + 22).toString() + 'px',
    viewColumns: false,
    print: false,
    download: true,
    sort: false,
    pagination: false,
    selectableRowsHideCheckboxes: true,
    customToolbar: (displayData) => {
      return (
        <>
          <CustomToolbar data={valorTablaInicio} columns={columns} titulo='Tabla_Inicio'></CustomToolbar>
        </>
      );
    },


    onChangeRowsPerPage(numberOfRows) {
      setRowsPerPage(numberOfRows);
    },
    onChangePage(page) {
      setPage(page);
    },
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        className: clsx({
          [classes.totales]: row[1] == undefined && !row[0].includes('Subtotal'),
          [classes.subtotal]: row[0].includes('Subtotal')
        }),
      };
    }

  }
  var optionsFooter3 = {
    customHeadRender: () => null,
    filter: false,
    responsive: 'standard',
    search: false,
    fixedHeader: true,
    tableBodyHeight: largoStockInicial >= 33 ? '700px' : (largoStockInicial * 22 + 22).toString() + 'px',
    viewColumns: false,
    print: false,
    download: true,
    sort: false,
    pagination: false,
    selectableRowsHideCheckboxes: true,
    customToolbar: (displayData) => {
      return (
        <>
          <CustomToolbar data={valorTablaInicio} columns={columns} titulo='Tabla_Inicio'></CustomToolbar>
        </>
      );
    },


    onChangeRowsPerPage(numberOfRows) {
      setRowsPerPage(numberOfRows);
    },
    onChangePage(page) {
      setPage(page);
    },
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        className: clsx({
          [classes.totales]: row[1] == undefined && !row[0].includes('Subtotal'),
          [classes.subtotal]: row[0].includes('Subtotal')
        }),
      };
    }

  }

  const ordenar = (data) => {
    const merged = data.reduce((r, { tipostock, tropa, fecrec, usuverddesc, remitente, c_481, cabstock, consig, kgrec, corral, hilton, no_hilton }) => {
      const key = `${usuverddesc}.${tipostock}`;
      if (!r[key]) {

        r[key] = { usuverddesc: 'Subtotal ' + usuverddesc, tipostock: tipostock, cabstock: 0, kgrec: 0, count: 0 };
      }

      r[key]['kgrec'] += parseInt(kgrec)

      r[key]['cabstock'] += parseInt(cabstock)
      r[key]['count'] += 1
      return r;


    }, {})
    const c_481 = data.reduce((r, { tipostock, tropa, fecrec,usuverddesc, remitente, c_481, cabstock, consig, kgrec, corral, hilton, no_hilton }) => {
      const key = `${usuverddesc}-c_481.${tipostock}`;
      if (!r[key]) {
        r[key] = { usuverddesc: 'Subtotal Cuota 481', tipostock: tipostock, cabstock: 0, kgrec: 0, count: 0 };
      }
      if (c_481 == true) {
        r[key]['kgrec'] += parseInt(kgrec)

        r[key]['cabstock'] += parseInt(cabstock)
        r[key]['count'] += 1
      }

      return r;
    }, {})
    const hilton = data.reduce((r, { tipostock, tropa, fecrec, usuverddesc, remitente, c_481, cabstock, consig, kgrec, corral, hilton, no_hilton, programa }) => {
      const key = `${usuverddesc}-hilton.${tipostock}`;
      if (!r[key]) {
        r[key] = { usuverddesc: 'Subtotal Hilton', tipostock: tipostock, cabstock: 0, kgrec: 0, count: 0 };
      }
      if (hilton == true) {

        r[key]['kgrec'] += parseInt(kgrec)

        r[key]['cabstock'] += parseInt(cabstock)
        r[key]['count'] += 1
      }

      return r;
    }, {})
    const no_hilton = data.reduce((r, { tipostock, tropa, fecrec, usuverddesc, remitente, c_481, cabstock, consig, kgrec, corral, hilton, no_hilton, programa }) => {
      const key = `${usuverddesc}-no_hilton.${tipostock}`;
      if (!r[key]) {
        r[key] = { usuverddesc: 'Subtotal No Hilton', tipostock: tipostock, cabstock: 0, kgrec: 0, count: 0 };
      }
      if (no_hilton == true) {
        r[key]['kgrec'] += parseInt(kgrec)

        r[key]['cabstock'] += parseInt(cabstock)
        r[key]['count'] += 1
      }

      return r;
    }, {})

    const TOTAL = data.reduce((r, { tipostock, tropa, fecrec, usuverddesc, remitente, c_481, cabstock, consig, kgrec, corral, hilton, no_hilton, programa }) => {
      const key = tipostock
      if (!r[key]) {

        r[key] = { usuverddesc: 'Total ', tipostock: tipostock, cabstock: 0, kgrec: 0, count: 0 };
      }

      r[key]['kgrec'] += parseInt(kgrec)
      r[key]['cabstock'] += parseInt(cabstock)

      r[key]['count'] += 1
      return r;


    }, {})

    const ordenado = [
      ...data.filter(x => x.usuverddesc == 'Exportación' && x.c_481 == true),
      ...data.filter(x => x.usuverddesc == 'Exportación' && x.hilton == true),
      ...data.filter(x => x.usuverddesc == 'Exportación' && x.no_hilton == true),

      ...data.filter(x => x.usuverddesc == 'Otros Paises'),
      ...data.filter(x => x.usuverddesc == 'Consumo'),
      ...data.filter(x => x.usuverddesc == 'Conserva'),
      ...data.filter(x => x.usuverddesc == 'Inc')

    ];

    for (const [key, valor] of Object.entries(merged)) {
      const pieces = key.split('.')
      const usuario = pieces[0]
      const tipo = pieces[1]
      const index = ordenado.findLastIndex(v => v.usuverddesc == usuario && v.tipostock == tipo)
      ordenado.splice((index + 1), 0, valor);
    }
    ordenado.join()
    for (const [key, valor] of Object.entries(c_481)) {
      const pieces = key.split('.')
      const usuario = key.split('-')[0]

      const tipo = pieces[1]
      if (usuario == 'Exportación' && valor['cabstock'] > 0) {
        const index = ordenado.findLastIndex(v => v.c_481 == true && v.tipostock == tipo && v.usuverddesc == 'Exportación')
        ordenado.splice((index + 1), 0, valor)
      }
    }
    ordenado.join()
    for (const [key, valor] of Object.entries(hilton)) {
      const pieces = key.split('.')
      const usuario = key.split('-')[0]
      const tipo = pieces[1]
      if (usuario == 'Exportación' && valor['cabstock'] > 0) {
        const index = ordenado.findLastIndex(v => v.hilton == true && v.tipostock == tipo && v.usuverddesc == 'Exportación')
        ordenado.splice((index + 1), 0, valor)
      }

    }
    ordenado.join()
    for (const [key, valor] of Object.entries(no_hilton)) {
      const pieces = key.split('.')
      const usuario = key.split('-')[0]
      const tipo = pieces[1]
      if (usuario == 'Exportación' && valor['cabstock'] > 0) {
        const index = ordenado.findLastIndex(v => v.no_hilton == true && v.tipostock == tipo && v.usuverddesc == 'Exportación')
        ordenado.splice((index + 1), 0, valor)
      }

    }
    ordenado.join()
    for (const [key, valor] of Object.entries(TOTAL)) {
      ordenado.push(valor)

    }
    return ordenado
  }

  const setDate = (event) => {
    setFecha(moment(event).format("YYYY-MM-DD"))
    //console.log(fecha)
  }

  const cargarData = async () => {
    setIsLoading2(true)
    setErrorServidor(false)
    setLargoStockActual(0);
    setLargoStockInicial(0);
    setValorTablaActual([]);
    setValorTablaInicio([]);
    setHoraListaMatanza(null)


    UserService.getStockResult(fecha, type).then(
      response => {
        if (response.status == 200) {
          const valueReduce = []
          const ordenado = ordenar(response.data)
          const InfoParaTAblas = tableData(ordenado)
          setValorTablaActual(InfoParaTAblas[0]);
          setValorTablaInicio(InfoParaTAblas[1]);
          setLargoStockActual(InfoParaTAblas[0].length);
          setLargoStockInicial(InfoParaTAblas[1].length);

          setIsLoading2(false)

        } else {

        }
      },
      error => {
        setValorTablaActual([]);
        setValorTablaInicio([]);
        setErrorServidor(true)
        setIsLoading2(false)
        if (error.response.status == 401) {

          signOut(props.history)
        }

      }
    );

    UserService.getHoraListaMatanza(fecha).then(
      response => {
        if (response.status == 200) {
          setHoraListaMatanza(response.data?.data)
        }
      }
    )


  }

  useEffect(() => {
    setValorTablaActual([]);
    setValorTablaInicio([]);
    setHoraListaMatanza(null)
    setIsLoading2(true)

    UserService.getStockResult(moment().format("YYYY-MM-DD"), type).then(
      response => {
        if (response.status == 200) {
          const valueReduce = []
          const ordenado = ordenar(response.data)
          const InfoParaTAblas = tableData(ordenado)
          setValorTablaActual(InfoParaTAblas[0]);
          setValorTablaInicio(InfoParaTAblas[1]);
          setIsLoading2(false);

          setLargoStockActual(InfoParaTAblas[0].length);
          setLargoStockInicial(InfoParaTAblas[1].length);

        } else {


        }
      },
      error => {
        setValorTablaActual([]);
        setValorTablaInicio([]);
        setErrorServidor(true)
        setIsLoading2(false)
        if (error.response.status == 401) {
          signOut(props.history)
        }
      }
    );

    UserService.getHoraListaMatanza(moment().format("YYYY-MM-DD")).then(
      response => {
        if (response.status == 200) {
          setHoraListaMatanza(response.data?.data)
        }
      }
    )
  }, []);



  const tableData = (data) => {
    const datatableData1 = []
    const datatableData3 = []

    for (const [key, value] of Object.entries(data)) {

      if (value['tipostock'] == '3') {
        datatableData1.push([value['usuverddesc'], value['tropa'], value['fecrec'] != undefined && value['fecrec'] != null ? moment(value['fecrec']).format('DD/MM/YY') : null ,(value['no_hilton'] == true && value['usuverddesc'] == 'Exportación' ? 'No Hilton' : value['hilton'] == true && value['usuverddesc'] == 'Exportación' ? 'Hilton' : value['c_481'] == true && value['usuverddesc'] == 'Exportación' ? 'Cuota 481' : ''), value['especies'], value['remitente'], value['consig'], value['cabstock'], value['kgrec'], (Math.round((value['kgrec'] / value['cabstock']) * 100) / 100).toFixed(1), value['corral'], value["programa"]])
      }
      else {
        datatableData3.push([value['usuverddesc'], value['tropa'], value['fecrec'] != undefined && value['fecrec'] != null ? moment(value['fecrec']).format('DD/MM/YY') : null,(value['no_hilton'] == true && value['usuverddesc'] == 'Exportación' ? 'No Hilton' : value['hilton'] == true && value['usuverddesc'] == 'Exportación' ? 'Hilton' : value['c_481'] == true && value['usuverddesc'] == 'Exportación' ? 'Cuota 481' : ''), value['especies'], value['remitente'], value['consig'], value['cabstock'], value['kgrec'], (Math.round((value['kgrec'] / value['cabstock']) * 100) / 100).toFixed(1), value['corral'], value["programa"]])
      }

    }
    return [datatableData1, datatableData3]
  };

  const columnsStock = [
    "Usuario", {
      name: "Tropa",
      options: {
        customBodyRender: (value) => {
          if (value) {
            return (<div className={classes.centerContent}>{value}</div>)
          }
        },
        setCellProps: () => (isSmallScreen && {style: { maxWidth: "80px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } })
      }
    },
    {
      name: "Recepcion",
      options: {
        customBodyRender: (value) => {
          if (value) {
            return (<div className={classes.centerContent}>{value}</div>)
          }
        },
        setCellProps: () => (isSmallScreen && {style: { maxWidth: "80px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } })
      }
    },
    {
      name: "Cuota",
      options: {
        customBodyRender: (value) => {
          if (value) {
            return (<div className={classes.centerContent}>{value}</div>)
          }
        },
        setCellProps: () => (isSmallScreen && {style: { maxWidth: "85px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } })

      }
    },
    {
      name: "Especies",
      options: {
        customBodyRender: (value) => {
          if (value) {
            return (<div className={classes.centerContent}>{value}</div>)
          }
        },
        setCellProps: () => (isSmallScreen && {style: { maxWidth: "80px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } })
      }
    }, 
    {name: "Remitente",
    options: {
      setCellProps: () => (isSmallScreen && {style: { maxWidth: "120px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } })
    }}, 
    {name: "Consignatario",
    options: {
      setCellProps: () => (isSmallScreen && {style: { maxWidth: "120px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } })
    }}, 
    {
      name: "Cabezas",
      options: {
        customBodyRender: (value) => {
          if (value) {
            return (<div className={classes.centerContent}>{value}</div>)
          }
        },
        setCellProps: () => (isSmallScreen && {style: { maxWidth: "70px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } })
      }
    },
    {
      name: "Kilos",
      options: {
        customBodyRender: (value) => {
          if (value) {
            return (<div className={classes.centerContent}>{value}</div>)
          }
        },
        setCellProps: () => (isSmallScreen && {style: { maxWidth: "70px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } })
      }
    },
    {
      name: "Kg. Prom",
      options: {
        customBodyRender: (value) => {
          if (value) {
            return (<div className={classes.centerContent}>{value}</div>)
          }
        },
        setCellProps: () => (isSmallScreen && {style: { maxWidth: "70px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } })
      }
    },
    {
      name: "Corral",
      options: {
        customBodyRender: (value) => {
          if (value) {
            return (<div className={classes.centerContent}>{value}</div>)
          }
        },
        setCellProps: () => (isSmallScreen && {style: { maxWidth: "70px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } })
      }
    },
    {
      name: "Programa",
      options: {
        customBodyRender: (value) => {
          if (value) {
            return (<div className={classes.centerContent}>{value}</div>)
          }
        },
        setCellProps: () => (isSmallScreen && {style: { maxWidth: "120px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } })
      }
    }
  ];

  return (
    <>
      {/* <PageTitle title="Gestión de stocks en corrales" /> */}
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>


          {/* <Widget disableWidgetMenu
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          > */}
          <Grid container
            alignItems='center'
            lg={12} md={12} sm={12} xs={12}
            spacing={4}>
            <Grid item lg={2} md={6} sm={12} xs={12} style={{ marginTop: '15px' }}>
              {/* <InputLabel id="type">Seleccione la fecha</InputLabel> */}

              <ResponsiveDatePickers
                value={fecha}
                label="Date desktop"
                inputFormat="DD/MM/YY"
                setValorFecha={setDate}
              ></ResponsiveDatePickers>
            </Grid>
            <Grid item
              lg={1} md={6} sm={12} xs={12} style={{ alignItems: 'center', display: 'flex', justifyContent:'center' }}
            >
              {isLoading2 ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  className={classes.buttonSubmit}

                  onClick={() =>
                    cargarData()}
                  variant="contained"
                  color="primary"

                  fullWidth
                >
                  Buscar
                </Button>
              )}

            </Grid>
            {errorServidor && (
            <Grid item lg={12} md={12} sm={12} xs={12} style={{ alignItems: 'center', display: 'flex', justifyContent:'center' }}>
            <Fade in={errorServidor}>
              <Typography color="secondary" className={classes.errorMessage}>
                Por favor vuelva a intentarlo.
              </Typography>
            </Fade>
            </Grid>
            )}
          </Grid>
          {/* <MuiThemeProvider theme={getMuiTheme()}> */}
          {/* <CustomToolbar data={datatableData1} ></CustomToolbar>  */}
          {valorTablaActual.length > 0 && (
          <MUIDataTable
            title={<span>
              Stock actual:{" "}
              <strong>{FechaDelDia}</strong>
            </span>}
            data={valorTablaActual}
            options={optionsFooter2}
            columns={columnsStock}
          >
          </MUIDataTable>
          )}
          {/*  </MuiThemeProvider> */}
          {/*     <MuiThemeProvider theme={getMuiTheme()}> */}
          {valorTablaInicio.length > 0 && (
          <MUIDataTable
            title={<span>
              Stock al inicio del dia:{" "}
              <strong>{moment(fecha).format('DD/MM/YY')} - {horaListaMatanza}</strong>
            </span>}
            data={valorTablaInicio}
            options={optionsFooter3}
            columns={columnsStock}
          >
          </MUIDataTable>
          )}
          {/* </MuiThemeProvider> */}
          {/* </Widget> */}
        </Grid>
      </Grid>

    </>
  );
}
