import React, { useState, useEffect, useRef, useContext } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  Person as AccountIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  CameraRear,
} from "@material-ui/icons";
import AlarmIcon from "@mui/icons-material/Alarm";
import SourceIcon from "@mui/icons-material/Source";
import GiteIcon from "@mui/icons-material/Gite";
import FoodBankIcon from "@mui/icons-material/FoodBank";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import InventoryIcon from "@mui/icons-material/Inventory";

import PieChartIcon from "@mui/icons-material/PieChart";
// styles
import useStyles from "./styles";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
//import Dot from "./components/Dot";
import GradingIcon from "@mui/icons-material/Grading";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ArticleIcon from '@mui/icons-material/Article';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import HouseIcon from '@mui/icons-material/House';
import FolderIcon from '@mui/icons-material/Folder';
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

import UserStateContext from "../../context/UserContext";

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();
  const user = useContext(UserStateContext);

  const structure = [ 
    { id: 1, 
      label: "Usuarios", 
      link: "/app/tables", 
      icon: <AccountIcon />, 
      permission: user.user.permissions.usuarios_can_view 
    },
    {
      id: 2,
      label: "Hacienda",
      //link: "/app/estadoCompras",
      icon: <HouseIcon />,
      permission: (user.user.permissions.compras_can_view ||
        user.user.permissions.dte_can_view
      ),
      children: [
        {
          id: 21,
          label: "Compras",
          link: "/app/estadoCompras",
          icon: <AlarmIcon />,
          permission: user.user.permissions.compras_can_view,
        },
        { id: 22, 
          label: "Dte", 
          link: "/app/dte", 
          icon: <PlaylistAddIcon />,
          permission: user.user.permissions.dte_can_view,
        },
        {
          id: 23,
          label: "Gestión Doc.",
          link: "/app/gestionDoc",
          icon: <AssignmentIcon />,
          permission: user.user.permissions.gestionDoc_can_view,
        },
        { id: 24, 
          label: "Cashflow", 
          link: "/app/Cashflow", 
          icon: <AttachMoneyIcon />,
          permission: user.user.permissions.cashflow_can_view,
        },
      ]

    },
    { id: 3, 
      label: "Faena", 
      //link: "/app/romaneo", 
      icon: <FolderIcon /> ,
      permission: (user.user.permissions.faena_can_view ||
        user.user.permissions.dashboard_can_view ||
        user.user.permissions.lista_matanza_can_view ||
        user.user.permissions.marcas_marbling_can_view
      ),
      children: [
        {
          id: 31,
          label: "Romaneos",
          link: "/app/romaneo",
          icon: <SourceIcon /> ,
          permission: user.user.permissions.faena_can_view,
        },
        {
          id: 32,
          label: "Dashboard",
          link: "/app/dashboard",
          icon: <PieChartIcon />,
          //color: theme.palette.tables.headerText,
          permission: user.user.permissions.dashboard_can_view,
        },
        { id: 33, 
          label: 'Lista Matanza', 
          link: '/app/listaMatanza', 
          icon: <ArticleIcon />,
          permission: user.user.permissions.lista_matanza_can_view,
        },
        { id: 34, 
          label: 'Marcas Marbling', 
          link: '/app/marcasMarbling', 
          icon: <AssignmentTurnedInIcon />,
          permission: user.user.permissions.marcas_marbling_can_view,
        },
      ]
    },
    {
      id: 4,
      label: "Corrales",
      //link: "/app/stock",
      icon: <InventoryIcon />,
      permission: (user.user.permissions.stock_can_view ||
        user.user.permissions.checklist_can_view
      ),
      children: [
        {
          id: 41,
          label: "Stock",
          link: "/app/stock",
          icon: <InventoryIcon />,
          permission: user.user.permissions.stock_can_view,
        },
        {
          id: 42,
          label: "CheckList",
          link: "/app/checkList",
          icon: <GradingIcon />,
          permission: user.user.permissions.checklist_can_view,
        },
      ]
    },
    { id: 9, 
      label: "Invernada", 
      //link: "/app/Feedlot", 
      icon: <GiteIcon />,
      permission: (user.user.permissions.feedlot_can_view),
      children: [
        {
          id: 1,
          label: "Logistica",
          link: "/app/Feedlot",
          icon: <FoodBankIcon />,
          permission: user.user.permissions.feedlot_can_view,
        }
      ]
    },
  ];

  // global
  var { isSidebarOpened } = useLayoutState();

  var layoutDispatch = useLayoutDispatch();
  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  const toogle = () => {
    toggleSidebar(layoutDispatch);
  };
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;
    if (ref.current) {
      toogle();
    }
  };


  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div ref={ref} className={classes.mobileBackButton}>
        <IconButton>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List
        ref={ref}
        className={classes.sidebarList}
        //onClick={handleClickOutside}
      >
        {structure.map((link) => {
          const isActive = location.pathname === link.link;
          if (!link.permission) {
            return
          } else {
            return (
              <SidebarLink
                toogle={toogle}
                key={link.id}
                location={location}
                isActive={isActive}
                isSidebarOpened={isSidebarOpened}
                {...link}
              />
            );
          }
        })}
        {}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
      if (isSidebarOpened == true) {
        toggleSidebar(layoutDispatch);
      }
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
      toggleSidebar(layoutDispatch);
    }
  }
}

export default withRouter(Sidebar);
