import { useEffect, useState, useCallback, useMemo, useRef, memo } from "react"
import moment from "moment"
import MUIDataTable from "mui-datatables";
import ResponsiveDatePickers from "../../components/dateRange/datePicker";
import UserService from "../../context/UserService";
import { CircularProgress, Button, Typography, Grid } from "@material-ui/core";
import Box from '@material-ui/core/Box';


const ListaMatanza = memo(() => {
    const [fecha, setFecha] = useState(moment().format('YYYY-MM-DD'));
    const [loading, setLoading] = useState(false);
    const [listaResponse, setListaResponse] = useState({ data: null, error: null, success: null });
    const [error, setError] = useState(false);
    const ref = useRef(null);

    const centeredColumn = useMemo(() => (
        {
            setCellProps: () => ({ style: { textAlign: 'center' } }),
        }
    ), [])

    const columns = useMemo(() => ([
        {
            id: 'secuencia',
            name: 'Sec',
            options: {
                ...centeredColumn
            }

        },
        {
            id: 'turno',
            name: 'T',
            options: {
                ...centeredColumn
            }
        },
        {
            id: 'n_mediares',
            name: 'N° Mediares',
            options: {
                ...centeredColumn
            }
        },
        {
            id: 'tropa',
            name: 'Tropa',
            options: {
                ...centeredColumn
            }
        },
        {
            id: 'cabzs_afaena',
            name: 'Cab. Fae.',
            options: {
                ...centeredColumn
            }
        },
        {
            id: 'usuario',
            name: 'Usuario',
        },
        {
            id: 'cabzs_ing',
            name: 'Tot. Cab.',
            options: {
                ...centeredColumn
            }
        },
        {
            id: 'especie',
            name: 'Especie',
            options: {
                ...centeredColumn
            }
        },
        {
            id: 'dest_com',
            name: 'Dest. Com.',
            options: {
                ...centeredColumn
            }
        },
        {
            id: 'fae_para',
            name: 'Fae Para',
            options: {
                ...centeredColumn
            }
        },
        {
            id: 'corral',
            name: 'Corral',
            options: {
                ...centeredColumn
            }
        },
        {
            id: 'destino',
            name: 'Destino',
        },
        {
            id: 'prom',
            name: 'Prom',
            options: {
                ...centeredColumn
            }
        },
        {
            id: 'calidad_aa',
            name: 'Angus',
            options: {
                ...centeredColumn
            }
        },
        {
            id: 'programa',
            name: 'Programa',
        }
    ]), [])

    const scrollToRef = useCallback(() => {
        setTimeout(() => {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }, 1000);
        //ref.current.scrollIntoView({ behavior: 'smooth' });
    }, [ref])

    const getLista = useCallback(async (fecha) => {
        setLoading(true);
        setError(false);
        setListaResponse({ data: null, error: null, success: null });
        let response;
        try {
            response = await UserService.getListaMatanza(fecha);
            if (response.status === 200) {
                setListaResponse(response.data);
                if (response.data.error) {
                    throw new Error(response.data.error);
                } else {
                    scrollToRef();
                }
            } else {
                throw new Error('Error al obtener la lista de matanza');
            }
        } catch (error) {
            setListaResponse({ data: null, error: error?.response?.data?.error || error.message, success: false });
            setError(true);
        } finally {
            setLoading(false);
        }
    })

    const setDate = useCallback((event) => {
        setFecha(moment(event).format("YYYY-MM-DD"))
    })

    const onSearch = useCallback(() => {
        getLista(fecha);
    }, [fecha])

    useEffect(() => {
        getLista(fecha);
    }, [])

    const lista = useMemo(() => {
        const valid_columns = new Set(columns.map(column => column.id));
        return listaResponse.data?.reduce((acc, item) => {
            const valid_keys = Object.keys(item).filter(key => valid_columns.has(key));
            const filtered_item = valid_keys.reduce((obj, key) => {
                obj.push(item[key]);
                return obj;
            }, []);
            acc.push(filtered_item);
            return acc;
        }, [])
    }, [listaResponse.data])

    return (
        <>
            <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box flexWrap="wrap" display="flex" alignItems="center" flexDirection="row" style={{ gap: 10 }}>
                        <ResponsiveDatePickers
                            value={fecha}
                            inputFormat='DD/MM/YYYY'
                            setValorFecha={setDate}
                        />
                        {loading ? <CircularProgress size={22} /> : <Button variant="contained" color="primary" onClick={onSearch}>Buscar</Button>}
                        {error && <Typography variant="body1" color="error">{listaResponse.error}</Typography>}
                    </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} ref={ref}>
                    {(listaResponse.data && !listaResponse.error) && <MUIDataTable
                        data={lista}
                        columns={columns}
                        options={{
                            selectableRows: false,
                            print: false,
                            download: true,
                            filter: false,
                            viewColumns: false,
                            pagination: false,
                            search: true,
                            responsive: 'standard',
                            sort: false
                        }}
                    />}
                </Grid>
            </Grid>
        </>
    )
})

export default ListaMatanza;